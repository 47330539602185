import $ from 'jquery'
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import user from '@/assets/user'
import error from '@/assets/error'
import jquery from 'jquery'
import Network from '@/assets/network';

import iView from 'iview'
Vue.use(iView)

import 'iview/dist/styles/iview.css'
import './app.less'

const USER_NAME = '';

window.$ = jquery;

$(document).bind("ajaxSend", function (elm, xhr, s) {
  if (process.env.NODE_ENV == 'development') {
    s.url = s.url
  }

  if (s.loading) {
    iView.Message.loading({
      content: s.loadingText || 'Loading...',
      duration: 0
    });
  }

  if (user.getToken()) {
    xhr.setRequestHeader('authorization', 'Bearer ' + user.getToken());
  }
});

$(document).bind('ajaxComplete', (elm, xhr, s) => {

  if (s.loading) {

    iView.Message.destroy();
  }
  var resp = xhr.responseJSON

  // if(resp.code==1){
  //   iView.Message.error({
  //     content: '账号或密码输入错误',
  //   });
  //   return
  // }

  if (xhr.status != '200') {

    if (xhr.status == '403') {
      iView.Message.error({
        content: '没有访问权限！'
      });

      // user.clear();
      //
      // vue.$router.push({
      //   name: 'account_login'
      // });

      return;
    }
    if (xhr.status == '401' && xhr.responseJSON.code == 'auth_token_expired'){
      iView.Message.error({
        content: '请求超时，请重新登录！'
      });

      setTimeout(() => {
        user.clear();
        vue.$router.push({
          name: 'account_login'
        });
        window.location.reload()
      }, 800);

      return;
    }else if(xhr.status == '401' && s.url == '/api/auth/login'){
      iView.Message.error({
        content:'用户名或密码错误！'
      });
      return;
    }else if(xhr.status == '401'){
      if (!s.disabledError && xhr.responseJSON.code != 'success') {
        iView.Message.error({
          content: xhr.responseJSON.error
        });
      }
      return;
    }

    if(xhr.status == '500' || xhr.status == '502' || xhr.status == '503' || xhr.status == '504' ){
      iView.Message.error({
        content: '服务器错误！'
      });
      return;
    }

    iView.Message.error({
      content: '系统异常，请稍后再试！',
    });

  }


});

Vue.prototype.$network = Network;

document.addEventListener('mousewheel', function (e) {
  e = e || window.event;
  if ((e.wheelDelta && event.ctrlKey) || e.detail) {
    event.preventDefault();
  }
}, { capture: false, passive: false});

document.addEventListener('keydown', function (event) {
  if ((event.ctrlKey === true || event.metaKey === true)
    && (event.keyCode === 61 || event.keyCode === 107
      || event.keyCode === 173 || event.keyCode === 109
      || event.keyCode === 187 || event.keyCode === 189)) {
    event.preventDefault();
  }
}, false);

let vue = new Vue({
  el: '#app',
  router,
  components: { App },
  template: '<App/>',
  store,
});

