<template>
  <div class="pointer-2d-wrap">
    <div @click="onClick" :class="{
      'pointer-icon': true,  
      'pointer-icon-active': active,  
    }">
      <img :src="pointer.avatar"/>
    </div>

    <div v-if="active" class="pointer-detail">
      <detail :pointer="pointer"></detail>
    </div>
  </div>
</template>

<script>
  import Detail from './detail';

  export default {
    components: {
      Detail
    },  

    props: {
      pointer: {
        type: Object,
        default: null
      },

      active: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {

      }
    },

    methods: {
      onClick () {
        this.$emit('on-click'); 
      }
    }
  };
</script>

<style lang="less">
  @import "~@/assets/css/mixin.less";

  .pointer-2d-wrap {
    width: 70px;
    height: 70px;
    position: relative;

    .pointer-icon {
      width: 100%;
      height: 100%;
      opacity: 1;
      border-radius: 35px;

      cursor: pointer;

      &.pointer-icon-active {
        opacity: 1;
        box-shadow: 0 0 20px #7BD9EC;
      }

      &:hover {
        opacity: 1;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    .pointer-detail {
      position: absolute;
      width: 300px;
      height: 400px;
      background: #fff;
      bottom: 0;
      left: -310px;
      transform: translateY(50%);
      border: 1px solid #2B3765;
      background: linear-gradient(180deg, rgba(118, 145, 245, 0.86) 0%, rgba(10, 15, 27, 0.86) 100%);
      opacity: 1;
      border-radius: 20px;
      color: #fff;
      overflow-y: auto;
    }
  }
  
</style>
