<template>
  <transition name="page">
    <Layout class="full main-bg">
      <div class="model-base-title">
        <div class="base-logo">
          <img src="../model/images/home_logo.png"/>
        </div>
        
        <div class="base-name">
          之年智能楼宇IBMS三维平台

          <div class="base-name-tip">
            Zhi Nian Intelligent Building IBMS 3D Platform
          </div>
        </div>

        <div class="base-time">
          
        </div>
      </div>

      <div class="login-form">
        <div class="form-fix-wrap">
          <div class="form-title">
            <p>欢迎您</p>
            <p>请登录您的账户</p>
          </div>

          <Form class="m_t" label-position="top" :model="loginForm" ref="loginForm">
            <FormItem label="" prop="username">
              <div class="input-item">
                <input
                  v-model="loginForm.username"
                  placeholder="请输入账号"
                  style="border:0;background:transparent;"
                />
              </div>
            </FormItem>

            <FormItem label="" prop="password">
              <div class="input-item">
                <input
                  v-model="loginForm.password"
                  type="password"
                  placeholder="请输入密码"
                  style="border:0;background:transparent;"
                />
              </div>
            </FormItem>

            <FormItem class="action-item">
              <i-button
                @click="login()"
                style="display: block; width:64%;background-color:#7691F5;color:#fff;border: none;margin: 0 auto;height: 40px;border-radius: 20px;"
                >登录</i-button>
            </FormItem>
          </Form>
        </div>
      </div>
    </Layout>
  </transition>
</template>

<script>
import user from "@/assets/user";

export default {
  methods: {
    registe() {
      this.$router.push({
        name: "account_registe"
      });
    },

    selectShop(shops) {
      let selectedShops = [];
      let shopIds = [];

      shops.forEach(shop => {
        if (shopIds.indexOf(shop.id) == -1) {
          selectedShops.push(shop);
          shopIds.push(shop.id);
        }
      });

      this.shops = selectedShops;
      this.showSelectShop = true;
    },

    onShop(shop) {
      user.setShop(shop);
    },

    login() {
      this.$refs["loginForm"].validate(valid => {
        if (valid) {
          $.ajax({
            url: "/api/auth/login",
            data: JSON.stringify(this.loginForm),
            contentType: "application/json",
            dataType:"json",
            loading: 1,
            method: 'post',
          }).then(ret => {
            if (ret.code != 'success') {
              return;
            }

            user.setToken(ret.data.token);

            this.$router.push({
              name: "model_index"
            });
          });
        }
      });
    }
  },

  data() {
    return {
      loginForm: {
        username: "",
        password: ""
      },

      showSelectShop: false,
      shops: [],

      ruleValidate: {
        username: [
          {
            required: true,
            message: "请输入登录名/手机号码！",
            trigger: "blur"
          }
        ],
        password: [
          {
            required: true,
            message: "请输入登录密码！",
            trigger: "blur"
          }
        ]
      }
    };
  },

  created() {}
};
</script>

<style scoped lang="less">
  @import "~@/assets/mixin.less";

  .main-bg {
    background: @mainColorBg url('https://fan-01.oss-cn-beijing.aliyuncs.com/1657022872546.png') no-repeat center bottom;
    background-size: 100% auto;
    display: flex;
    align-items: center;
    flex-direction: row;

    .model-base-title {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      width: 1920px;
      height: 112px;
      background: #fff;
      z-index: 99;
      background: url('../model/images/home_title.png') no-repeat center center;
      background-size: 100% 100%;
      text-align: center;

      .base-time {
        position: absolute;
        right: 15px;
        top: 25px;
        color: #f7f7f7;
      }

      .base-name {
        font-weight: bold;
        color: #E0E3EF;
        font-size: 32px;
        margin-top: 10px;

        .base-name-tip {
          font-weight: normal;
          font-size: 13px;
        }
      }

      .base-logo {
        width: 237px;
        height: 36px;
        position: absolute;
        left: 10px;
        top: 20px;

        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }

    .login-form {
      position: relative;
      position: absolute;
      width: 500px;
      top: 200px;
      left: 50%;
      transform: translateX(-50%);
      padding: 20px 0;
      background: linear-gradient(180deg, rgba(10,15,27,0.5600) 0%, rgba(12,19,38,0.5600) 100%);
      color: #fff;
      border-radius: 20px;
      border: 1px solid #2B3765;

      .input-item {
        padding: 5px 10px;
        margin-top: 20px;
        background: #f7f7f7;
        width: 15vw;
        margin: 0 auto;
        border: 1px solid #ccc;
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        border-radius: 20px;

        i {
          font-size: 1vw;
        }

        input {
          width: 0;
          font-size: 16px;
          flex: 1 1 auto;
        }
      }


      .page-title {
        text-align: center;
        font-weight: bold;
        color: #fff;
        margin-bottom: 20px;

        .page-title-p1 {
          width: 244px;
          height: 38px;
          background: url('https://fan-01.oss-cn-beijing.aliyuncs.com/1654745489575.png') no-repeat center center; 
          margin: 15px auto;
        }

        .page-title-p2 {
          font-size: 1.6vw;  
        }
      }

      .page-bottom {
        text-align: center;
        color: #fff;
        font-size: 0.8vw;
        margin-top: 20px;
      }

      .form-fix-wrap {
        border-radius: 15px;
        padding: 30px 20px 40px 20px;
        color: #fff;

        .action-item {
          margin-top: 20px;
        }

        .form-title {
          text-align: center;
          font-size: 1vw;
          margin-bottom: 30px;
        }

        .form-end {
          margin-top: 40px;
          text-align: center;
          color: #fff;
        }

      }
    }

  }
</style>
