import * as THREE from 'three';
import { MeshLine, MeshLineMaterial, MeshLineRaycast } from 'three.meshline';


class RoadAnimation {
  constructor (threeInfo, linesData) {

    this.threeInfo = threeInfo;
    this.linesData = linesData;
    this.spheres = [];
    this.centerObj = null;

    this.init();
  }

  deserialize (points) {
    let vertices = [];
    
    for (let i = 0; i < points.length; i++) {
     vertices.push( new THREE.Vector3( points[i][0],  points[i][1], points[i][2] ));
    }

    let curve = new THREE.CatmullRomCurve3(vertices);
    const geometry = new THREE.BufferGeometry();
    geometry.vertices = [];

    const splinePoints = curve.getPoints(50);
    
    for (let i = 0; i < splinePoints.length; i++) {
      geometry.vertices.push(splinePoints[i]);
    }
    
    return { curve, geometry };
  }

  init () {
    this.currentPosition = 0.01;
    // this.linePoints();
    this.addLine();
  }

  animate () {

    this.linesData.forEach((spline) => {

      spline.lines.forEach((lineItem) => {
        lineItem.currentPosition = lineItem.currentPosition < 1 ? Math.min(lineItem.currentPosition + 1 / 2000, 1) : 0.01;

        const point = lineItem.curve.getPointAt(lineItem.currentPosition);
         
        lineItem.line.position.set(point.x - lineItem.start.x, 0.21, point.z - lineItem.start.z);

      });

    });  

    const time = Date.now() * 0.00005;

    if (this.centerObj) {
      this.centerObj.rotation.y += 0.0003;
    }
  }

  linePoints () {

    let sphereMaterial = new THREE.MeshBasicMaterial({
      color: 0xff0000,
    });

    let sphereGeometry = new THREE.OctahedronGeometry(1, 2);

    this.linesData.forEach((spline) => {

      let points = this.deserialize(spline).geometry; // makeSquare(3);
    
      for (let i = 0; i < points.vertices.length; i++) {
         const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
         sphere.position.copy(points.vertices[i]);
         this.threeInfo.scene.add(sphere);
      } 
    });

  }

  addLine () {
    const map = new THREE.TextureLoader().load( '/static/model/building/maps/disc.png' );

    const materials = [
      new MeshLineMaterial({
        color: new THREE.Color("rgb(145, 80, 16)"),

        opacity: 1,
        lineWidth : 0.5,
        map: map,
        useMap: 1,

        alphaMap: map,
        useAlphaMap: 1,

        alphaTest: 0.1,
        // depthWrite: false,
        // depthTest: false,
        // alphaTest: .5,
        // transparent: true,
        // side: THREE.DoubleSide
      }),

      new MeshLineMaterial({
        color: new THREE.Color("rgb(70, 50, 26)"),
        opacity: 0.7,
        lineWidth : 0.5,
        map: map,
        useMap: 1,

        alphaMap: map,
        useAlphaMap: 1,

        alphaTest: 0.1,
        // depthWrite: false,
        // depthTest: false,
        // alphaTest: .5,
        // transparent: true,
        // side: THREE.DoubleSide
      })
    ];


    const LineCount = 30;


    this.linesData.forEach((spline, lineIdx) => {
      spline.lines = [];

      let arr = [];

      for (var i = 0; i < LineCount; i++) {
        arr.push(i);
      }

      // debugger;

      arr.forEach((idx) => {

        let lineItem = {};

        lineItem.currentPosition = 0.01 * idx * 100 / LineCount;

        console.log(lineItem.currentPosition);

        const curve = this.deserialize(spline).curve;
        const geo = new THREE.BufferGeometry();
        const positions = [];

        let p;
        
        for (let i = 0; i < 12; i++) {  
          p = curve.getPointAt( lineItem.currentPosition + (0.003 * i) );

          positions.push(p.x, 0.21, p.z);

          if (i == 0) {
            lineItem.start = {
              x: p.x,
              y: 0.21,
              z: p.z
            }
          }
        }

        geo.setAttribute( 'position', new THREE.BufferAttribute(new Float32Array(positions), 3 ) );
        
        let line = new MeshLine();
        line.setGeometry(geo);

        let r = Math.floor(Math.random() * materials.length);

        console.log(r);

        let lineMesh = new THREE.Mesh(line, materials[r] );

        lineMesh.layers.enable(1);

        lineItem.line = lineMesh;
        lineItem.curve = curve;


        this.threeInfo.scene.add(lineMesh);

        spline.lines.push(lineItem);

      });
 
    });
    
  }

  point_effect () {
    const vertices = [];

    const sprite = new THREE.TextureLoader().load( '/static/model/demo/maps/disc.png' );

    const geometry = new THREE.SphereGeometry(1, 32, 32);
    const material = new THREE.MeshBasicMaterial( { color: 0xffffff } );

    let parameters = [
      [[ 1.0, 0.2, 0.5 ], null, 6 ],
      [[ 0.95, 0.1, 0.5 ], null, 2.5 ],
      [[ 0.90, 0.05, 0.5 ], null, 3 ],
      [[ 0.85, 0, 0.5 ], null, 3 ],
      [[ 0.80, 0, 0.5 ], null, 3 ]
    ];

    let centerObj = new THREE.Object3D();


    for ( let j = 0; j < parameters.length; j ++ ) {

      for ( let i = 0; i < 500; i ++ ) {

        let x = Math.random() * 2000 - 1000;
        let y = Math.random() * 2000;
        let z = Math.random() * 2000 - 1000;

        if (Math.abs(x) < 100) {
          x = x < 0 ? x - 100 : x + 100;
        } 

        if (Math.abs(z) < 100) {
          z = z < 0 ? z - 100 : z + 100;
        }

        geometry.radius = parameters[j][2];

        const sphere = new THREE.Mesh(geometry, material);

        // debugger;

        sphere.translateX(x);
        sphere.translateY(y);
        sphere.translateZ(z);

        sphere.rotation.x = Math.random() * 6;
        sphere.rotation.y = Math.random() * 6;
        sphere.rotation.z = Math.random() * 6;

        centerObj.add(sphere);

        // this.spheres.push(sphere);
      }
    }

    this.centerObj = centerObj;
    this.threeInfo.scene.add( centerObj );
  }

  building_effect (cityBuildings) {
    const { geometry } = cityBuildings;
    geometry.computeBoundingBox();
    geometry.computeBoundingSphere();

    const { max, min } = geometry.boundingBox;


    const materials = Array.isArray(cityBuildings.material) ? cityBuildings.material : [cityBuildings.material]
    materials.forEach((material) => {
      // material.opacity = 1;

      material.onBeforeCompile = (shader) => {

        shader.uniforms.uMax = {
          value: max
        }


        shader.uniforms.uMin = {
          value: min
        }


        shader.uniforms.uLightColor = {
          value: new THREE.Color('#98FFDC')
        }


        shader.uniforms.uBorderWidth = {
          value: 5
        }
        
        

        shader.uniforms.uCircleTime = {
          value: 5
        }
        

        shader.uniforms.uTime = {
          value: 0
        }
        

        const vertex = `
          varying vec4 vPosition;

          void main() {
             vPosition = modelMatrix * vec4(position,1.0);
        `
        shader.vertexShader = shader.vertexShader.replace("void main() {", vertex);
        // 补充颜色渐变动画需要的各种变量
        const fragment = `

          uniform mat4 modelMatrix;
         varying vec4 vPosition;
          uniform vec3 uMax; 
          uniform vec3 uMin; 
          uniform float uBorderWidth; 
          uniform vec3 uLightColor;
          uniform float uCircleTime; 
          uniform float uTime; 
          vec4 uMax_world;
          vec4 uMin_world;

          void main() {
            // 转世界坐标

            uMax_world =  modelMatrix * vec4(uMax,1.0);
            uMin_world =  modelMatrix * vec4(uMin,1.0);
  
        `;
        const fragmentColor = `
       

          vec3 distColor = outgoingLight;
           

          float residue = uTime - floor(uTime / uCircleTime) * uCircleTime;
          float rate = residue / uCircleTime;

          float lightOffset = rate * (uMax_world.y - uMin_world.y);


          if (uMin_world.y + lightOffset < vPosition.y && uMin_world.y + lightOffset + uBorderWidth > vPosition.y) {

            gl_FragColor = vec4(uLightColor, diffuseColor.a);
          } else {

            gl_FragColor = vec4(distColor, diffuseColor.a);
          }
        `;
        shader.fragmentShader = shader.fragmentShader.replace("void main() {", fragment)
        shader.fragmentShader = shader.fragmentShader.replace("gl_FragColor = vec4( outgoingLight, diffuseColor.a );", fragmentColor);
      }
    });

  }

}

export default RoadAnimation;