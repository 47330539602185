<template>
  <div class="pointer-main-wrap">
    <div class="pointer-item" v-for="pointer, idx in pointers ">
      <default-point @on-click="onClick(pointer)" :ref="'pointer_' + pointer.id" :value="pointer.id" :active="active == pointer" :pointer="pointer"></default-point>
    </div>
  </div>
</template>

<script>
  import DefaultPoint from './default';
  import * as THREE from 'three';
  import { CSS2DRenderer, CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer.js';
  import { mapGetters } from "vuex";

  let threeInfo;
  let labelRenderer;

  export default {
    computed: {
      ...mapGetters(["activePointer", "fixedCamera"])
    },

    components: {
      DefaultPoint
    },

    data () {
      return {
        pointers: [],

        active: null,
      }
    },

    created () {
      window.addEventListener('resize', () => {
        this.onResize();
      });

      this.$watch('activePointer', () => {
        if (!this.activePointer) {
          this.active = null;

          return;
        }

        if (this.active && this.active.id == this.activePointer) {
          return;
        }

        let pointer = this.pointers.filter((_pointer) => {
          return _pointer.id == this.activePointer;
        })[0];

        if (pointer) {
          this.onClick(pointer, true);
        }
      });
    },

    mounted () {
      labelRenderer = new CSS2DRenderer();
      labelRenderer.setSize( window.innerWidth, window.innerHeight );
      labelRenderer.domElement.style.position = 'absolute';
      labelRenderer.domElement.style.top = '0px';

      document.body.appendChild(labelRenderer.domElement);

      this.$emit('ready', labelRenderer.domElement);


      labelRenderer.domElement.addEventListener('contextmenu', () => {
        if (this.active) {
          this.active = null;
          this.$store.dispatch('activePointer', null);
          return;
        }
      });
    },

    methods: {
      onResize () {
        labelRenderer.setSize(window.innerWidth, window.innerHeight);
      },

      onClick (pointer, nav = true) {
        if (pointer == this.active) {
          this.active = null;
          this.$store.dispatch('activePointer', null);
          return;
        }

        this.active = pointer;

        this.$store.dispatch('activePointer', this.active.id);

        if (nav && pointer.camera && !this.fixedCamera) {
          threeInfo.cameraControls.setLookAt(
            pointer.camera.position.x,
            pointer.camera.position.y,
            pointer.camera.position.z,

            pointer.camera.target.x,
            pointer.camera.target.y,
            pointer.camera.target.z,

            true
          );
        }
      },

      renderPointer () {
        this.pointers.forEach((p, idx) => {
          let $el = this.$refs['pointer_' +  p.id][0].$el;

          // 如果有scene 则表示已经加入场景了
          if ($el.label2D) {
            return;
          }

          if (!p.position.x) {
            return;
          }

          const label2D = new CSS2DObject($el);
          label2D.position.set(p.position.x, p.position.y, p.position.z);
          threeInfo.scene.add( label2D );

          p.label2D = label2D;
          $el.label2D = label2D;
        });
      },

      addPointer (p) {
        this.pointers.push(p);

        this.$nextTick(() => {
          this.renderPointer();
        });
      },

      clearPoint () {
        this.pointers.forEach((p) => {
          threeInfo.scene.remove(p.label2D);
          p.label2D = null;

          // dom 也移除
          let $el = this.$refs['pointer_' +  p.id][0].$el;
          $el.label2D = null;
        });

        this.pointers = [];
      },

      setThreeInfo (_threeInfo) {
        threeInfo = _threeInfo;
      },

      render () {
        if (threeInfo && threeInfo.scene) {
          labelRenderer.render(threeInfo.scene, threeInfo.camera);
        }

        this.pointers.forEach((p) => {
          let start = new THREE.Vector3();

          if (!p.label2D) {
            return;
          }

          start.copy(p.label2D.position);

          let dist = start.distanceTo(threeInfo.camera.position);
          let size = 1 / dist * 10;

          size = size > 1 ? 1 : size;

          let $el = this.$refs['pointer_' +  p.id][0].$el;
          $el.style.transform += ` scale(${size})`; 
        });
      }
    }
  };
</script>

<style lang="less">
  @import "~@/assets/css/mixin.less";

  .pointer-main-wrap {
    width: 0;
    height: 0;
    overflow: hidden;
  }
  
</style>
