<template>
  <div class="info-details-nas">
    <div class="info-block-main">
      <div class="huiyi-status">
        使用占比

        <div v-if="huiyiSet.data" class="nas-block">
          <div :style="{
            width: ((huiyiSet.data.used / huiyiSet.data.total) * 100) + '%'
          }" class="nas-used">
          </div>

          <span class="nas-percent">
            {{ ((huiyiSet.data.used / huiyiSet.data.total).toFixed(2) * 100)}}%
          </span>
        </div>
      </div>

      <div class="huiyi-status">
        剩余可用

        <div class="nas-block-leave">
          {{ ((huiyiSet.data.total - huiyiSet.data.used) / 1024 / 1024).toFixed(2) }}G / {{ ((huiyiSet.data.total) / 1024 / 1024).toFixed(2) }}G
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import echarts from 'echarts';
  import { mapGetters } from "vuex";

  export default {
    computed: {
      ...mapGetters(["perWidth", "lightState"]),
    },

    props: {
      useData: {
        type: Object,
        default: null
      },
    },

    mounted () {

    },

    created () {
      this.loadState();
    },

    data () {
      return {
        huiyiSet: {
          data: false,
          show: false,
          list: [],
        }
      }
    },

    methods: {
      loadState () {
        $.ajax({
          url: '/api/device/nas/status',
        }).then((ret) => {
          if (ret.code != 'success') {
            return;
          }

          this.huiyiSet.data = ret.data;
        });  
      },

      onSwitch () {
        this.$store.dispatch('set_lightState', !this.lightState);
      }
    }
  };
</script>

<style lang="less" scoped>
  @import "~@/assets/css/mixin.less";

  .info-details-nas {
    .huiyi-status {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      color: #fff;
      margin-bottom: 20px;

      .nas-block {
        display: block;
        background: #151D38;
        border-radius: 10px;
        width: 0;
        flex: 1 1 auto;
        height: 23px;
        margin-left: 10px;
        display: flex;
        justify-content: flex-end;
        overflow: hidden;
        position: relative;

        .nas-percent {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          font-size: 12px;
        }

        .nas-used {

          background: #1DADFF;
          height: 23px;
          text-align: center;
          color: #fff;
        }
      }

      .huiyi-history {
        width: 75px;
        height: 34px;
        border: 1px solid #2D3862;
        background: linear-gradient(180deg, #7691F5 0%, #7BD9EC 100%);
        opacity: 1;
        border-radius: 10px;
        line-height: 34px;
        text-align: center;
        cursor: pointer;

        &.huiyi-history-off {
          background: linear-gradient(180deg, #efefef 0%, #cccccc 100%);
          color: #999;
        }
      }

      .huiyi-flag {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        &.huiyi-flag-disabled {
          &:before {
            background: red;
          }
        }

        &:before {
          content: "";
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 4px;
          background: #12B796;
          margin-right: 10px;
        }
      }
    }
  }
  
</style>
