<template>
  <div class="info-common-wrap">
    <div class="info-block-main-left">
      <div class="info-block-main">
        <div class="info-block-content">
          <div class="info-title ">
            设备统计 <span>Equipment Statistics</span>
          </div>

          <div class="info-block-content-detail">
            <div class="info-block-detail">
              <div class="info-details-item">
                <div class="pie-wrap-top">
                  <div  ref="main" class="pie-left">

                  </div>

                  <div  ref="left" class="pie-left">

                  </div>
                </div>
              </div>

              <div class="info-details-item">
                <div class="pie-wrap">
                  <div class="pie-info">
                    <div v-if="summary" class="type-list custom-scroll-less">
                      <div class="type-item" v-for="item, idx in summary.subCategories">
                        <span :style="{
                          'background': COLOR_MAPS[idx]
                        }" class="type-item-color"></span>
                        {{ item.name }}
                      </div>
                    </div>
                  </div>

                  <div class="pie-right">
                    <div ref="top" class="pie-right-top"></div>
                    <div ref="bottom" class="pie-right-bottom"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="info-block-main info-details-last ">
        <div class="info-block-content ">
          <div class="info-title ">智能水电表 <span>Smart water meter</span></div>

          <div class="info-block-content-detail">
            <div class="ai-count-wrap">
              <div class="ai-count-item">
                <img src="./images/light.png">
                <div class="ai-detail-title">
                  总用电量
                </div>
                <div class="ai-detail-count">
                  {{ countSet.light ? countSet.light.electricityConsumption : '' }}<span>kw/h</span>
                </div>
              </div>

              <div class="ai-count-item ai-count-item-warter">
                <img src="./images/water.png">
                <div class="ai-detail-title">
                  总用水量
                </div>
                <div class="ai-detail-count">
                  {{ countSet.water ? countSet.water.waterConsumption.toFixed(1) : '' }}<span>m^3</span>
                </div>
              </div>
            </div>


            <div class="ai-chart-wrap">
              <div class="ai-elec-wrap" ref="ai_elec"></div>
              <div class="ai-water-wrap" ref="ai_water"></div>
            </div>

          </div>
        </div>
      </div>

      <div class="info-block-main info-block-main-chart ">
        <div class="info-block-content ">
          <div class="info-title ">环境检测 <span>Environmental Monitoring</span></div>

          <div class="info-block-content-detail">
            <div class="environment">
              <div><img src="../../images/thermometer.png" alt="" style="width: 11px;margin-top: 5px"></div>
              <div>温度</div>
              <div>25℃</div>
            </div>
            <div class="environment">
              <div><img src="../../images/shidu.png" alt="" style="width: 11px;margin-top: 5px"></div>
              <div>湿度</div>
              <div>67%rh</div>
            </div>
            <div class="environment">
              <div><img src="../../images/co.png" alt=""></div>
              <div>CO</div>
              <div>2.66g/km</div>
            </div>
            <div class="environment">
              <div><img src="../../images/jiaqu.png" alt=""></div>
              <div>甲醛</div>
              <div>0.05mg</div>
            </div>
            <div class="environment">
              <div><img src="../../images/PM2.5.png" alt=""></div>
              <div>PM2.5</div>
              <div>10</div>
            </div>
<!--            <div class="warning-wrap">-->
<!--              <div class="warning-lengend">-->
<!--                <div class="lengend-item">烟感</div>-->
<!--                <div class="lengend-item">双鉴探测器</div>-->
<!--                <div class="lengend-item">声光报警器</div>-->
<!--              </div>-->
<!--&lt;!&ndash;              <div class="warning-wrap-chart" ref="warning"></div>&ndash;&gt;-->
<!--            </div>-->
          </div>
        </div>
      </div>
    </div>

<!-- 右侧-->
    <div class="info-block-main-right">
      <div class="info-block-content alarmInformation" style="padding-bottom: 15px;box-sizing: border-box">
          <div class="info-titleList">
            工单统计 <span>Smart water meter</span>
            <div style="float: right;margin-right: 10px;">
             <div  @click="publicFun(index)" :class="item.class" v-for="(item, index) in titleList">
               {{item.label}}
             </div>
            </div>
          </div>
           <div class="work-pic">
             <div ref="workLeft" class="pie-right-top" style="height: 124px;margin-left: 5px;"></div>
             <div ref="workcenter" class="pie-right-top" style="height: 124px;"></div>
             <div ref="workRight" class="pie-right-top" style="height: 124px;"></div>
           </div>
      </div>
<!--    中间位置-->
      <div class="info-block-content alarmInformation " style="min-height:330px">
        <div class="info-title ">
          报警信息 <span>Alarm Information</span>
        </div>
        <div>
          <table>
            <thead>
              <tr>
                <th>报警时间</th>
                <th>级别</th>
                <th>设备名称</th>
                <th>事件</th>
                <th>位置</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item, idx in alarmInformationData">
                <td>{{monentData(item.eventTime)}}</td>
                <td>一般</td>
                <td>{{item.deviceName}}</td>
                <td>{{item.eventTypeName}}</td>
                <td>防区{{item.zoneId}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
<!--告警次数统计-->
      <div class="info-block-main info-block-main-chart" >
        <div class="info-block-content ">
          <div class="info-title ">告警次数统计 <span>Alarm times</span></div>

          <div class="info-block-content-detail">
            <div class="warning-wrap">
              <div class="givealarmImg">
                <img src="../../images/gaojing.png" alt="" style="width:390px;height: 155px;">
              </div>
              <div class="work-pic" style="    text-align: center;
            color: #FFFFFF;
            opacity: .7;
            font-size: 12px;
            margin-top: 6px;">
                <div>2022.09.20</div>
                <div>2022.09.21</div>
                <div>2022.09.22</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import echarts from 'echarts';
  import moment from 'moment'
  const COLOR_MAPS = ['#7691F5', '#12B796', '#1DADFF', '#24DCF7', '#1C8AC3'];

  export default {

    computed: {
      ...mapGetters(["perWidth"]),
    },

    data () {
      return {
        COLOR_MAPS: COLOR_MAPS,

        summary: null,

        list: [],

        currentId: null,

        echartSet: {
        },

        countSet: {
          water: null,
          light: null
        },
        alarmInformationData:[],
        activebtn: 0,
        lastactivebtn: -1,
        titleList: [
          {label: '今日', class: 'huiyi-history active'},
          {label: '本周', class: 'huiyi-history'},
        ],
        todayNum:'3',
        todayData:'今日完成',
        overtime:'0'
      }
    },

    created () {
      this.loadInfo();
      this.loadLightCount();
      this.loadWaterCount();
      this.loadWaterHistry();
      this.loadElecHistry();
      this.alarmInformation()
    },

    mounted () {
      this.initWarningChart();

      $(window).resize(() => {
        Object.keys(this.echartSet).forEach((key) => {
          this.echartSet[key].resize();
        });
      });
    },

    methods: {
      publicFun(index){
        this.lastactivebtn = this.activebtn;// 记录上一个节点 为移除样式
        this.activebtn = index;// 记录新节点 添加样式
        if (this.lastactivebtn != this.activebtn) { // 如果点击同一节点 就不变啦
          this.titleList[this.activebtn].class = 'huiyi-history active';
          this.titleList[this.lastactivebtn].class = 'huiyi-history';
        }
        if (index == '0'){
          this.todayNum = 3
          this.todayData = '今日完成'
          this.overtime = 0
          this.workLIstLeftFun();
          this.workLIstRightFun();
        }else {
          this.todayNum = 18
          this.todayData = '本周完成'
          this.overtime = 1
          this.workLIstLeftFun();
          this.workLIstRightFun();
        }
      },
      loadInfo () {
        $.ajax({
          url: '/api/asset/statistics',
          loading: 1,
          success: (ret) => {
            if (ret.code != 'success') {
              return;
            }

            this.summary = ret.data;
            this.$emit('summary', this.summary);

            this.initMainChart();

            this.initLeftChart();

            this.initBottomLeft();
            this.initBottomRight();
            this.workLIstLeftFun();
            this.workLIstCentFun();
            this.workLIstRightFun();

          }
        });
      },

      loadElecHistry () {
        let data = {
          start: moment().add(-7, 'days').format('YYYY-MM-DD'),
          end: moment().add(-1, 'days').format('YYYY-MM-DD'),
        }

        $.ajax({
          url: '/api/device/smartMeter/electricityHistory',
          data: data,
          loading: false,
          disabledError: true,
          success: (ret) => {
            if (ret.code != 'success') {
              return;
            }

            this.countSet.elec_history = ret.data;
            this.initElecChart(this.countSet.elec_history);
          }
        });
      },

      initElecChart (data) {
        let trendOption;

        trendOption = {
          color: ['#4de1fd', '#0654ea'],

          xAxis: {
            type: 'category',

            axisLabel: {
              color: '#fff',
              fontSize: 11,
              // rotate: 25,
            },

            axisLine: {
              lineStyle: {
                color: 'rgba(255, 255, 255, 0)', // 颜色
              }
            },

            data: data.map((item) => {
              return moment(item.updateDate).format('M/D');
            })
          },

          yAxis: {
            type: 'value',
            show: true,
            splitNumber: 2,

            axisLabel: {
              color: '#46aee1',
              fontSize: 11,
              margin: -4,
            },

            axisLine: {
              show: false,
              lineStyle: {
                color: 'rgba(255, 255, 255, 0)', // 颜色
              }
            },

            splitLine: {
              show: true,

              lineStyle: {
                color: ['#27457b'],
                width: 1,
                type: 'solid'
              }
            },
          },

          grid: {
            show: false,
            top: '10%',
            // left: '0',
            right: '0',
            bottom: '20',
          },

          tooltip: {
            show: true,
            backgroundColor: '#7691F5',
            borderWidth: 1,
            position: function (point, params, dom, rect, size) {
              return [point[0], '10%'];
            },
            formatter: '{b0} <br>{c0}kw/h',
            textStyle: {
              fontSize: this.perWidth * 0.8
            }
          },

          series: [
            {
              type: 'bar',

              showBackground: true,
              barWidth: '40%',
              barCategoryGap: '0%',
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: '#50a7f0' },
                  { offset: 0.5, color: '#50a7f0' },
                  { offset: 1, color: '#336a9d' }
                ]),

                barBorderRadius: 5
              },

              data: data.map((item, idx) => {
                return {
                  value: item.electricityConsumption,

                  itemStyle: {
                    borderRadius: 100,
                    shadowBlur: 2
                  },
                }
              })
            },
          ]
        };

        this.echartSet.elec = echarts.init(this.$refs.ai_elec);
        this.echartSet.elec.setOption(trendOption);
      },

      loadWaterHistry () {
        let data = {
          start: moment().add(-365, 'days').format('YYYY-MM-DD'),
          end: moment().add(-1, 'days').format('YYYY-MM-DD'),
        }

        $.ajax({
          url: '/api/device/smartMeter/waterHistory',
          data: data,
          loading: false,
          disabledError: true,
          success: (ret) => {
            if (ret.code != 'success') {
              return;
            }
            //只保留6条数据
            this.countSet.water_history = ret.data.slice(0,6);
            this.initWaterChart(this.countSet.water_history);
          }
        });
      },

      initWaterChart (data) {
        let trendOption;

        trendOption = {
          color: ['#4de1fd', '#0654ea'],

          xAxis: {
            type: 'category',

            axisLabel: {
              color: '#fff',
              fontSize: 11,
              // rotate: 25,
            },

            axisLine: {
              lineStyle: {
                color: 'rgba(255, 255, 255, 0)', // 颜色
              }
            },

            data: data.map((item) => {
              return moment(item.updateDate).format('M/D');
            })
          },

          yAxis: {
            type: 'value',
            show: true,
            splitNumber: 2,

            axisLabel: {
              color: '#46aee1',
              fontSize: 11,
              margin: -4,
            },

            axisLine: {
              show: false,
              lineStyle: {
                color: 'rgba(255, 255, 255, 0)', // 颜色
              }
            },

            splitLine: {
              show: true,

              lineStyle: {
                color: ['#27457b'],
                width: 1,
                type: 'solid'
              }
            },
          },

          grid: {
            show: false,
            top: '10%',
            // left: '0',
            right: '0',
            bottom: '20',
          },


          tooltip: {
            show: true,
            position: function (point, params, dom, rect, size) {
              return [point[0], '10%'];
            },
            backgroundColor: '#7691F5',
            borderWidth: 1,
            formatter: '{b0} <br>{c0}m^3',
            textStyle: {
              fontSize: this.perWidth * 0.8,
              color: '#fff'
            }
          },

          series: [
            {
              type: 'bar',

              showBackground: true,
              barWidth: '40%',
              barCategoryGap: '0%',
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: '#15b296' },
                  { offset: 0.5, color: '#15b296' },
                  { offset: 1, color: '#1d7273' }
                ]),

                barBorderRadius: 5
              },

              data: data.map((item, idx) => {
                return {
                  value: item.waterConsumption,

                  itemStyle: {
                    borderRadius: 100,
                    shadowBlur: 2
                  },
                }
              })
            },
          ]
        };

        this.echartSet.water = echarts.init(this.$refs.ai_water);
        this.echartSet.water.setOption(trendOption);
      },

      loadWaterCount () {
         $.ajax({
          url: '/api/device/smartMeter/waterConsumption',
          loading: false,
          disabledError: true,
          success: (ret) => {
            if (ret.code != 'success') {
              return;
            }
            this.countSet.water = ret.data;
          }
        });
      },

      loadLightCount () {
         $.ajax({
          url: '/api/device/smartMeter/electricityConsumption',
          loading: false,
          disabledError: true,
          success: (ret) => {
            if (ret.code != 'success') {
              return;
            }

            this.countSet.light = ret.data;
          }
        });
      },

      initWarningChart () {
        let trendOption;

        trendOption = {
          color: ['#4de1fd', '#0654ea'],

          xAxis: {
            type: 'category',

            axisLabel: {
              color: '#fff',
              fontSize: 11,
              // rotate: 25,
            },

            axisLine: {
              lineStyle: {
                color: 'rgba(255, 255, 255, 0)', // 颜色
              }
            },

            data: ['11/01', '11/2', '11/03']
          },

          yAxis: {
            type: 'value',
            show: true,

            axisLabel: {
              color: '#46aee1',
              fontSize: 11,
            },

            axisLine: {
              lineStyle: {
                color: 'rgba(255, 255, 255, 0)', // 颜色
              }
            },

            splitLine: {
              show: true,

              lineStyle: {
                color: ['#27457b'],
                width: 1,
                type: 'solid'
              }
            },
          },

          // dataZoom: [
          //   {
          //     type: 'inside',
          //     start: 0,
          //     end: (4 / oriList.yList.length * 100),
          //     height: 15
          //   },
          //   {
          //     type: 'slider',
          //     start: 0,
          //     end: (4 / oriList.yList.length * 100),
          //     zoomLock: true,
          //     height: 15
          //   }
          // ],

          grid: {
            show: false,
            top: '3%',
            left: '0',
            right: '0',
            bottom: '20',
          },

          series: [
            {
              type: 'line',
              stack: 'Total',
              smooth: true,
              lineStyle: {
                width: 0
              },
              showSymbol: false,
              areaStyle: {
                opacity: 0.8,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgb(128, 255, 165)'
                  },
                  {
                    offset: 1,
                    color: 'rgb(1, 191, 236)'
                  }
                ])
              },
              emphasis: {
                focus: 'series'
              },

              // data: [1, 3, 4, 6, 4].map((item, idx) => {
              //   return {
              //     value: item,
              //
              //     label: {
              //       show: true,
              //       fontSize: 11,
              //       color: '#46aee1',
              //       position: 'top'
              //     },
              //   }
              // })
            },

            {
              type: 'line',
              stack: 'Total',
              smooth: true,
              lineStyle: {
                width: 0
              },
              showSymbol: false,
              areaStyle: {
                opacity: 0.8,
                // color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                //   {
                //     offset: 0,
                //     color: 'rgb(0, 221, 255)'
                //   },
                //   {
                //     offset: 1,
                //     color: 'rgb(77, 119, 255)'
                //   }
                // ])
              },
              emphasis: {
                focus: 'series'
              },

              // data: [3, 2, 5, 1, 6].map((item, idx) => {
              //   return {
              //     value: item,
              //
              //     label: {
              //       show: true,
              //       fontSize: 11,
              //       color: '#46aee1',
              //       position: 'top'
              //     },
              //   }
              // })
            },
          ]
        };

        this.echartSet.trend = echarts.init(this.$refs.warning);
        this.echartSet.trend.setOption(trendOption);
      },

      initMainChart () {
        let safeOption = {
          grid: {
            show: false,
            top: '3%',
            left: '3%',
            right: '3%',
            bottom: '3%',
          },

          series: [
            {
              name: '访问来源',
              type: 'pie',
              radius: ['60%', '80%'],
              center: ['50%', '50%'],

              labelLine: {
                show: false
              },

              label: {
                show: true,
                position: 'center',
                formatter: () => {
                  return [
                    `{a|${this.summary.total}}`,
                    '{b|设备总数}'
                  ].join('\n');
                },

                rich: {
                  a: {
                    color: '#7691F5',
                    fontSize: this.perWidth * 1.8
                  },

                  b: {
                    color: '#fff',
                    fontSize: this.perWidth * 0.7
                  }
                }
              },

              data: this.summary.subCategories.map((sub, i) => {
                return {
                  value: sub.total,
                  name: sub.name,
                  itemStyle: {
                    color: COLOR_MAPS[i]
                  }
                };
              })
            }
          ]
        };

        this.echartSet.main = echarts.init(this.$refs.main);
        this.echartSet.main.setOption(safeOption, false);
        this.echartSet.main.resize();
      },

      initLeftChart () {
        let safeOption = {
          grid: {
            show: false,
            top: '3%',
            left: '3%',
            right: '3%',
            bottom: '3%',
          },

          series: [
            {
              name: '访问来源',
              type: 'pie',
              radius: ['60%', '80%'],
              center: ['50%', '50%'],

              labelLine: {
                show: false
              },

              // emphasis: {
              //   label: {
              //     show: true,
              //     fontSize: '40',
              //     fontWeight: 'bold'
              //   }
              // },

              label: {
                show: true,
                position: 'center',
                formatter: () => {
                  return [
                    `{a|${this.summary.total}}`,
                    '{b|设备总数}'
                  ].join('\n');
                },

                rich: {
                  a: {
                    color: '#7691F5',
                    fontSize: this.perWidth * 1.8
                  },

                  b: {
                    color: '#fff',
                    fontSize: this.perWidth * 0.7
                  }
                }
              },

              data: [
                {
                  value: this.summary.online,
                  name: '在线',
                  itemStyle: {
                    color: '#7691F5'
                  }
                },
                {
                  value: this.summary.total - this.summary.online,
                  name: '离线',
                  itemStyle: {
                    color: '#151D38'
                  }
                }
              ]
            }
          ]
        };

        this.echartSet.left = echarts.init(this.$refs.left);
        this.echartSet.left.setOption(safeOption, false);
        this.echartSet.left.resize();
      },

      initBottomLeft () {
        let safeOption = {
          grid: {
            show: false,
            top: '3%',
            left: '3%',
            right: '3%',
            bottom: '3%',
          },

          series: [
            {
              name: '访问来源',
              type: 'pie',
              radius: ['80%', '67%'],
              center: ['50%', '50%'],

              labelLine: {
                show: false
              },

              // emphasis: {
              //   label: {
              //     show: true,
              //     fontSize: '40',
              //     fontWeight: 'bold'
              //   }
              // },

              label: {
                show: true,
                position: 'center',
                formatter: () => {
                  return [
                    `{a|${this.summary.online}}`,
                    '{b|在线}'
                  ].join('\n');
                },

                rich: {
                  a: {
                    color: '#7691F5',
                    fontSize: this.perWidth * 1
                  },

                  b: {
                    color: '#fff',
                    fontSize: this.perWidth * 0.6
                  }
                }
              },

              data: [
                {
                  value: this.summary.online,
                  name: '在线',
                  itemStyle: {
                    color: '#7691F5'
                  }
                }
              ]
            }
          ]
        };

        this.echartSet.top = echarts.init(this.$refs.top);
        this.echartSet.top.setOption(safeOption, false);
        this.echartSet.top.resize();
      },

      initBottomRight () {
        let safeOption = {
          grid: {
            show: false,
            top: '3%',
            left: '3%',
            right: '3%',
            bottom: '3%',
          },

          series: [
            {
              name: '访问来源',
              type: 'pie',
              radius: ['80%', '67%'],
              center: ['50%', '50%'],

              labelLine: {
                show: false
              },

              // emphasis: {
              //   label: {
              //     show: true,
              //     fontSize: '40',
              //     fontWeight: 'bold'
              //   }
              // },

              label: {
                show: true,
                position: 'center',
                formatter: () => {
                  return [
                    `{a|${this.summary.total - this.summary.online}}`,
                    '{b|离线}'
                  ].join('\n');
                },

                rich: {
                  a: {
                    color: '#7691F5',
                    fontSize: this.perWidth * 1
                  },

                  b: {
                    color: '#fff',
                    fontSize: this.perWidth * 0.6
                  }
                }
              },

              data: [
                {
                  value: this.summary.total - this.summary.online,
                  name: '离线',
                  itemStyle: {
                    color: '#151D38'
                  }
                }
              ]
            }
          ]
        };

        this.echartSet.bottom = echarts.init(this.$refs.bottom);
        this.echartSet.bottom.setOption(safeOption, false);
        this.echartSet.bottom.resize();
      },
      workLIstLeftFun () {
        let safeOption = {
          grid: {
            show: false,
            top: '3%',
            left: '3%',
            right: '3%',
            bottom: '3%',
          },
          series: [
            {
              name: '访问来源',
              type: 'pie',
              radius: ['70%', '95%'],
              center: ['50%', '50%'],
              hoverAnimation: false,
              labelLine: {
                show: false
              },
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: '#7691F5' },
                  { offset: 0.5, color: '#3B497B' },
                ]),

                barBorderRadius: 5
              },

              label: {
                show: true,
                position: 'center',
                formatter: () => {
                  return [
                    '{a|'+this.todayNum+'}',
                    '{b|'+this.todayData+'}'
                  ].join('\n');
                },

                rich: {
                  a: {
                    color: '#7691F5',
                    fontSize: this.perWidth * 1
                  },

                  b: {
                    color: '#fff',
                    fontSize: this.perWidth * 0.6
                  }
                }
              },

              data: [
                {
                  value: 1,
                  name: this.todayData,
                }
              ]
            }
          ]
        };

        this.echartSet.workLeft = echarts.init(this.$refs.workLeft);
        this.echartSet.workLeft.setOption(safeOption, false);
        this.echartSet.workLeft.resize();
      },
      workLIstCentFun () {
        let safeOption = {
          grid: {
            show: false,
            top: '3%',
            left: '3%',
            right: '3%',
            bottom: '3%',
          },
          series: [
            {
              name: '访问来源',
              type: 'pie',
              radius: ['70%', '95%'],
              center: ['50%', '50%'],

              labelLine: {
                show: false
              },
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: '#12B796' },
                  { offset: 0.5, color: '#095C4B' },
                ]),

                barBorderRadius: 5
              },
              hoverAnimation: false,
              label: {
                show: true,
                position: 'center',
                formatter: () => {
                  return [
                    `{a|2}`,
                    '{b|进行中}'
                  ].join('\n');
                },

                rich: {
                  a: {
                    color: '#7691F5',
                    fontSize: this.perWidth * 1
                  },

                  b: {
                    color: '#fff',
                    fontSize: this.perWidth * 0.6
                  }
                }
              },

              data: [
                {
                  value: 1,
                  name: '进行中',
                }
              ]
            }
          ]
        };

        this.echartSet.workcenter = echarts.init(this.$refs.workcenter);
        this.echartSet.workcenter.setOption(safeOption, false);
        this.echartSet.workcenter.resize();
      },
      workLIstRightFun () {
        let safeOption = {
          grid: {
            show: false,
            top: '3%',
            left: '3%',
            right: '3%',
            bottom: '3%',
          },
          series: [
            {
              name: '访问来源',
              type: 'pie',
              radius: ['70%', '95%'],
              center: ['50%', '50%'],

              labelLine: {
                show: false
              },
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: '#FF7F00' },
                  { offset: 0.5, color: '#804000' },
                ]),

                barBorderRadius: 5
              },
              hoverAnimation: false,
              label: {
                show: true,
                position: 'center',
                formatter: () => {
                  return [
                    '{a|'+this.overtime+'}',
                    '{b|超时}'
                  ].join('\n');
                },

                rich: {
                  a: {
                    color: '#7691F5',
                    fontSize: this.perWidth * 1
                  },

                  b: {
                    color: '#fff',
                    fontSize: this.perWidth * 0.6
                  }
                }
              },

              data: [
                {
                  value: 1,
                  name: '超时',
                  itemStyle: {
                    color: '#69360f'
                  }
                }
              ]
            }
          ]
        };

        this.echartSet.workRight = echarts.init(this.$refs.workRight);
        this.echartSet.workRight.setOption(safeOption, false);
        this.echartSet.workRight.resize();
      },
      //  报警信息
      alarmInformation  () {
        $.ajax({
          url: '/api/device/alarm/',
          loading: false,
          disabledError: true,
          method: 'get',
          data:{
            page:0,
            size:10
          }
        }).then(ret => {
          if (ret.code != 'success') {
            return;
          }

          this.alarmInformationData = ret.data.content
        });
      },
     monentData(data){
       var date = new Date(data);
       // var Y = date.getFullYear() + '-';
       var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '.';
       var D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate()) + ' ';

       var h = (date.getHours() < 10 ? '0' + (date.getHours()) : date.getHours()) + ':';
       var m = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes());
       // var s = (date.getSeconds() < 10 ? '0' + (date.getSeconds()) : date.getSeconds());
       var strDate =  M + D + h + m;
       return strDate
     }
    }
  };
</script>

<style scoped lang="less">
  @import "~@/assets/css/mixin.less";

  .info-common-wrap {
    .info-block-main-left {
      display: flex;
      flex-direction: column;
      position: fixed;
      left: 20px;
      color: #fff;
      top: 80px;
      width: 416px;
      bottom: 0;
      display: flex;
      flex-direction: column;

      .warning-wrap {
        overflow: hidden;
        .warning-lengend {
          display: flex;
          justify-content: flex-end;
          margin-bottom: 5px;

          .lengend-item {
            font-size: 11px;
            display: flex;
            align-items: center;
            margin-left: 5px;
            .one-line();

            &:last-child {
              margin-bottom: 0;
            }

            &:before {
              content: "";
              display: inline-block;
              width: 8px;
              height: 6px;
              background: #7691F5;
              margin-right: 10px;
            }
          }
        }

        .warning-wrap-chart {
          min-height: 140px;
          height: 100%;
          margin: 0 -10px;
        }
      }


      .info-details-last {
        height: 0;
        flex: 1 1 auto;
        max-height: 220px;

        .info-block-content {
          height: 100%;
          display: flex;
          flex-direction: column;

          .info-block-content-detail {
            height: 0;
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
          }
        }
      }

      .ai-chart-wrap {
        height: 0;
        flex: 1 1 auto;
        display: flex;

        >div {
          width: 0;
          flex: 1 1 auto;
          height: 100%;

          &:first-child {
            margin-right: 20px;
          }
        }
      }

      .ai-count-wrap {
        display: flex;

        .ai-count-item {
          display: flex;
          background-size: 100% 100%;
          width: 0;
          height: 100%;
          flex: 1 1 auto;
          justify-content: center;
          align-items: center;
          font-size: 12px;

          &.ai-count-item-warter {
            .ai-detail-count {
              color: #12B796;
            }
          }

          &:last-child {
            margin-bottom: 0;
            margin-left: 10px;
          }

          .ai-detail-count {
            font-size: 24px;
            color: #1DADFF;
            span {
              color: #fff;
              font-size: 12px;
            }
          }

          img {
            display: block;
            height: 24px;
            margin-bottom: 5px;
          }

        }
      }
      .environment {
        height: 35px;
        margin-bottom: 10px;
        line-height: 35px;
        text-align: center;
        //background: #7691F5;
        background-color: rgb(118 145 245 / 17%);
      }

      .environment div {
        float: left;
        width: calc(100% / 3.09);
        width: -webkit-calc(100% /3.09);
        width: -moz-calc(100% / 3.09);
        color: #FFFFFF;
        opacity: .9;
      }
      .environment div img{
        width: 20px;
        margin-top: 5px;
      }
    }


    .info-block-detail {
      color: #fff;


      .info-details-item {
        margin-bottom: 0px;

        &:last-child {
          margin-bottom: 0;
        }

        .pie-wrap-top {
          display: flex;

          .pie-left,
          .pie-right {
            height: 175px;
            width: 0;
            flex: 1 1 auto;
          }
        }

        .pie-wrap {
          display: flex;

          .pie-right {
            width: 150px;
            height: 100px;
            display: flex;

            >div {
              width: 75px;
              height: 75px;
            }
          }

          .pie-info {
            flex: 1 1 auto;
            width: 0;
            height: 100px;
            padding-left: 15px;
            margin-right: 10px;

            .type-list {
              height: 100%;

              .type-item {
                font-size: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 5px;

                &:last-child {
                  margin-bottom: 0;
                }

                .type-item-color {
                  display: inline-block;
                  width: 8px;
                  height: 6px;
                  background: #7691F5;
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }
    }

    .info-block-main-right {
      display: flex;
      flex-direction: column;
      position: fixed;
      right: 20px;
      color: #fff;
      top: 80px;
      width: 416px;
      bottom: 0;
      display: flex;
      flex-direction: column;

      .warning-wrap {
        overflow: hidden;
        .warning-lengend {
          display: flex;
          justify-content: flex-end;
          margin-bottom: 5px;

          .lengend-item {
            font-size: 11px;
            display: flex;
            align-items: center;
            margin-left: 5px;
            .one-line();

            &:last-child {
              margin-bottom: 0;
            }

            &:before {
              content: "";
              display: inline-block;
              width: 8px;
              height: 6px;
              background: #7691F5;
              margin-right: 10px;
            }
          }
        }
        .warning-wrap-chart {
          min-height: 140px;
          height: 100%;
          margin: 0 -10px;
        }
      }

      .info-titleList {
        color: #fff;
        margin-bottom: 10px;
        font-size: 16px;
        padding-top: 10px;
        padding-left: 15px;
        box-sizing:border-box;

        span {
          font-size: 12px;

        }

      }
      .huiyi-history {
        display: inline-block;
        width: 57px;
        height: 26px;
        border: 1px solid #7C90EF;
        opacity: 1;
        line-height: 26px;
        text-align: center;
        cursor: pointer;
        font-size: 12px;
      }
      .active{
        background: linear-gradient(180deg, #7691F5 0%, #7BD9EC 100%);
      }
      .givealarm{
        position: relative;
      }
      .givealarmImg{
        height: 154px;
        width: 100%;
      }
      .info-details-last {
        height: 0;
        flex: 1 1 auto;
        max-height: 220px;

        .info-block-content {
          height: 100%;
          display: flex;
          flex-direction: column;

          .info-block-content-detail {
            height: 0;
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
          }
        }
      }

      .ai-chart-wrap {
        height: 0;
        flex: 1 1 auto;
        display: flex;

        >div {
          width: 0;
          flex: 1 1 auto;
          height: 100%;

          &:first-child {
            margin-right: 20px;
          }
        }
      }

      .ai-count-wrap {
        display: flex;

        .ai-count-item {
          display: flex;
          background-size: 100% 100%;
          width: 0;
          height: 100%;
          flex: 1 1 auto;
          justify-content: center;
          align-items: center;
          font-size: 12px;

          &.ai-count-item-warter {
            .ai-detail-count {
              color: #12B796;
            }
          }

          &:last-child {
            margin-bottom: 0;
            margin-left: 10px;
          }

          .ai-detail-count {
            font-size: 24px;
            color: #1DADFF;
            span {
              color: #fff;
              font-size: 12px;
            }
          }

          img {
            display: block;
            height: 24px;
            margin-bottom: 5px;
          }

        }
      }
      .alarmInformation table{
        width: 100%;
        text-align:center ;
      }
      .alarmInformation thead tr{
        height: 40px;
        border-bottom: 1px dashed #233470;
        opacity: .7;
      }
      .alarmInformation thead tr th{
        font-size: 16px;
      }
      .alarmInformation tbody tr{
        height: 30px;
        opacity: .8;
      }
      .alarmInformation{
        margin-bottom:10px;
        border: 1px solid #2B3765;
        background: linear-gradient(180deg, rgba(10, 15, 27, 0.6) 0%, rgba(12, 19, 38, 0.6) 100%);
        opacity: 1;
        border-radius:20px;
      }
      .work-pic div{
        float: left;
        width: calc(100% / 3.09);
        width: -webkit-calc(100% /3.09);
        width: -moz-calc(100% / 3.09);

      }
    }
  }

</style>
