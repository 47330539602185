import * as THREE from 'three';
import EventDispatcher from '../utils/eventDispatcher.js'

const clock = new THREE.Clock();

class MeasurePoint extends EventDispatcher {
  constructor (threeInfo, option = {}) {
    super();
    this.threeInfo = threeInfo;
    this.raycaster = new THREE.Raycaster();
    this.pointer = new THREE.Vector2();

    let $dom = this.threeInfo.$dom ? this.threeInfo.$dom : this.threeInfo.renderer.domElement;

    $dom.addEventListener('pointermove', () => {
      this.pointer.x = ( event.clientX / $dom.offsetWidth ) * 2 - 1;
      this.pointer.y = - ( event.clientY / $dom.offsetHeight ) * 2 + 1;
    });


    this.draging = false;
    this.mousedowning = false;

    $dom.addEventListener('click', this.onMouseClick.bind(this), false);
    $dom.addEventListener('mousedown', this.onMouseDown.bind(this), false);
    $dom.addEventListener('mouseup', this.onMouseUp.bind(this), false);
    $dom.addEventListener('mousemove', this.onMouseMove.bind(this), false);

    const map = new THREE.TextureLoader().load( '/static/model/demo/maps/disc.png' );

    const material = new THREE.SpriteMaterial( {
      map: map,
      color: 0xff0000, 
      fog: true,
      sizeAttenuation: false,
      depthTest: false,
    });

    this.sphereInter = new THREE.Sprite(material );
    this.sphereInter.visible = false;
    this.sphereInter.scale.set(0.03, 0.03, 1);

    this.threeInfo.scene.add( this.sphereInter );

    this.state = 'close';

    this.testChildren = [];
    // this.initTest();
  }

  onMouseClick (e) {
    e.preventDefault();
  }

  onMouseDown () {
    this.mousedowning = true;
  }

  onMouseUp () {
    if (!this.draging && this.state == 'open') {
      if (this.sphereInter.visible) {
        let event = {
          position: this.sphereInter.position,
          object: this.sphereInter.object,
          type: 'point'
        }

        this.dispatchEvent(event);
      }
    }

    this.draging = false;
    this.mousedowning = false;
  }

  onMouseMove () {
    if (this.mousedowning) {
      this.draging = true;
    }
  } 

  addTest (item) {
    this.testChildren.push(item);
  }

  initTest () {
    const geometry = new THREE.BoxGeometry( 20, 20, 20 );

    for ( let i = 0; i < 2000; i ++ ) {

      const object = new THREE.Mesh( geometry, new THREE.MeshLambertMaterial( { color: Math.random() * 0xffffff } ) );

      object.position.x = Math.random() * 800 - 400;
      object.position.y = Math.random() * 800 - 400;
      object.position.z = Math.random() * 800 - 400;

      object.rotation.x = Math.random() * 2 * Math.PI;
      object.rotation.y = Math.random() * 2 * Math.PI;
      object.rotation.z = Math.random() * 2 * Math.PI;

      object.scale.x = Math.random() + 0.5;
      object.scale.y = Math.random() + 0.5;
      object.scale.z = Math.random() + 0.5;

      this.threeInfo.scene.add( object );
    }
  }

  open () {
    this.state = 'open';
  }

  close () {
    this.state = 'close';
  }

  render () {
    this.raycaster.setFromCamera(this.pointer, this.threeInfo.camera);

    let intersections = this.raycaster.intersectObjects(this.testChildren.filter(item => item.visible), true );
    let intersection = ( intersections.length ) > 0 ? intersections[ 0 ] : null;

    if (intersection) {
      this.sphereInter.visible = true;
      this.sphereInter.position.copy(intersection.point);
      this.sphereInter.object = intersection.object;
    } else {
      this.sphereInter.visible = false;
    }

  }
}

export default MeasurePoint;