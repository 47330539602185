<template>
  <Modal width="50%" title="摄像监控" v-model="canShow" :mask-closable="false" :closable="false" >
    <div ref="live" class="live-model-wrap">
      <div @click="onClose" class="action-close">
        <i class="iconfont icon-delete"></i>
      </div>


      <div v-if="[1, 4, 5].indexOf(liveData.id) == -1" class="camera-action-wrap">
        <div @click="onNav('left')" class="action-item action-left">
          <i class="iconfont icon-jiantou1"></i>
        </div>        
        <div @click="onNav('right')" class="action-item action-right">
          <i class="iconfont icon-jiantou1"></i>
        </div>
        <div @click="onNav('up')" class="action-item action-up">
          <i class="iconfont icon-jiantou1"></i>
        </div>        
        <div @click="onNav('bottom')" class="action-item action-bottom">
          <i class="iconfont icon-jiantou1"></i>
        </div>        
      </div>

      <video id="vid1" class="video-js">
        <source :src="`video/8aac6eccaab5496d9b1e6ac8856603be/${liveData.id}/index.m3u8`" type="application/x-mpegURL">
      </video>

    </div>

    <div slot="footer"></div>
  </Modal>
</template>

<script>
  import showMixin from '@/assets/show.mixin.js';
  import videojs from 'video.js';
  export default {
    props: {
      liveData: {
        type: Object,
      }
    },

    mixins: [showMixin],

    data () {
      return {
        videoSet: {
          width: 0,
          height: 0
        }
      };
    },

    watch: {
      canShow () {
        if (this.canShow) {

        } else {
          this.destory();
          this.$store.dispatch('activeLive', null);
        }
      }
    },

    created () {
      
    },

    mounted () {
      if (this.canShow) {
        this.initVideo();
      }
    },

    methods: {
      onNav (key) {
        let url = '';

        if (key == 'left') {
          url = `/api/device/camera/${this.liveData.id}/leftStart`
        }

        if (key == 'right') {
          url = `/api/device/camera/${this.liveData.id}/rightStart`
        }

        if (key == 'up') {
          url = `/api/device/camera/${this.liveData.id}/upStart`
        }

        if (key == 'bottom') {
          url = `/api/device/camera/${this.liveData.id}/downStart`
        }

        $.ajax({
          url: url,
          method: 'post',
          loading: 1,
          success: (ret) => {
            if (ret.code != 'success') {
              return;
            }

            setTimeout(() => {
              this.$Message.success({
                content: '操作成功（控制命令传送中，请稍后）',
                duration: 3
              });
            }, 200);
            // debugger;
          }
        });
      },

      onClose () {
        this.$store.dispatch('activeLive', null);
        this.destory();
      },

      destory () {
        this.player && this.player.dispose();
        this.player = null;
      },

      initVideo () {
        this.player = videojs('vid1', {
          fluid: true,
          controls: false,
          // liveui: true,
        });  

        this.player.autoplay('muted');
      }
    }
  };
</script>

<style lang="less">
  .live-model-wrap {
    position: relative;
    width: 100%;

    .camera-action-wrap {
      position: absolute;
      right: 20px;
      bottom: 20px;
      width: 150px;
      height: 150px;
      background: url('./images/circle.png') no-repeat center center;
      background-size: cover;
      z-index: 9;

      .action-item {
        position: absolute;
        cursor: pointer;

        &.action-right {
          right: 10px;
          top: 52px;
        }

        &.action-left {
          left: 10px;
          top: 52px;
          transform: rotateY(180deg);
        }

        &.action-up {
          left: 50px;
          top: 12px;
          transform: rotate(-90deg);
        }

        &.action-bottom {
          left: 50px;
          bottom: 5px;
          transform: rotate(90deg);
        }
      }

      i {
        font-size: 55px;
        line-height: 55px;
        color: #fff;
      }
    }

    .action-close {
      position: absolute;
      right: 0;
      top: -60px;
      cursor: pointer;

      i {
        color: #fff;
        font-size: 30px;
      }
    }
  }
</style>
