import * as THREE from 'three';
import { MeshLine, MeshLineMaterial, MeshLineRaycast } from 'three.meshline';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js';
import AnimationStar from '../animations/star'
import CameraControls from 'camera-controls';
import MeshReflectorMaterial from '@/assets/three/MeshReflectorMaterial.js';
import store from '@/store/index.js';
import MeasurePoint from '../tools/measurePoint.js'
import EventDispatcher from '../utils/eventDispatcher.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';


const clock = new THREE.Clock();

const CategoryCamera = [
  {
    ids: [19, 20],
    floor: 0,
    camera: {
      position: [5.7632849359463325, 6.76584909442841, 4.167015448932498],
      target: [5.763276846868545, -1.3232785026788327, 4.167015420545309]
    }
  }
]

const texture = new THREE.TextureLoader().load('/static/model/demo/T_DiBan01.png');
texture.wrapS = THREE.RepeatWrapping;
texture.wrapT = THREE.RepeatWrapping;
// texture.repeat.set(5, 5);

const LIGHT_OFF = new THREE.MeshStandardMaterial({
  color: new THREE.Color("rgb(255, 255, 255)"),
  transparent: true,
  opacity: 0.2,
  metalness: 0.4,
  depthWrite: false, 
});


const LIGHT_ON = new THREE.MeshStandardMaterial({
  color: new THREE.Color("rgb(248, 240, 76)"),
  emissive: new THREE.Color("rgb(248, 240, 76)"),
  
  // transparent: false,
  opacity: 1,
  metalness: 1,
  // depthWrite: false, 
});

const MODEL_PATH =  {
    // building: {
    //   // obj: '/static/model/building/S_JZWK.obj',
    //   // mtl: '/static/model/building/S_JZWK.mtl'

    //   obj: '/static/model/demo/S_JZWK.obj',
    //   mtl: '/static/model/demo/S_JZWK.mtl'
    // },

    // floor: {
    //   obj: '/static/model/demo/S_ZhouBian.obj',
    //   mtl: '/static/model/demo/S_ZhouBian.mtl',
    // },

  floors: [
    {
      obj: (process.env.NODE_ENV === 'production' ? '' : '')  + '/static/model/glb/F01.glb',
      mtl: (process.env.NODE_ENV === 'production' ? '' : '')  + '/static/model/glb/F01.mtl',

      rootObj: null,

      children: {

      },

      groups: {

        light: {
          keys: [
            'S_QiTa11636363'
          ],

          material: LIGHT_OFF,

          meshes: [],
        },  

        tv: {
          keys: [
            'S_QiTa22757575_1'
          ],

          // material: LIGHT_OFF,

          meshes: [],
        },  

        camera: {
          keys: [
            'S_TouYingYi_1', 'S_TouYingYi_2'
          ],

          // material: LIGHT_OFF,

          meshes: [],
        },  

        audio: {
          keys: [
            'S_YinXiang01_1'
          ],

          // material: LIGHT_OFF,

          meshes: [''],
        },  

        box: {
          keys: [
            'S_GuiTi02_2'
          ],

          // material: LIGHT_OFF,

          meshes: [''],
        },  


        ground: {
          keys: [
            'S_DiBan_F01'
          ],

          material: new THREE.MeshStandardMaterial({

            // alphaMap: new THREE.TextureLoader().load( '/static/model/glb/T_DiBan01.png' ),
            map: texture,
            transparent: true,
            side: THREE.DoubleSide,
            // opacity: 0.2,
            // metalness: 0.4
          }),

          mirror: false,
          meshes: [],
        },  
      }
    },

    {
      obj: (process.env.NODE_ENV === 'production' ? '' : '')  + '/static/model/glb/F02.glb',
      mtl: (process.env.NODE_ENV === 'production' ? '' : '')  + '/static/model/glb/F02.mtl',

      rootObj: null,

      children: {

      },

      groups: {
        wall: {
          keys: [
            'S_QT_F2',
          ],

          material: new THREE.MeshStandardMaterial({
            color: new THREE.Color("rgb(26, 27, 163)"),
            transparent: true,
            opacity: 0.2,
            metalness: 0.4,
            depthWrite: false, 
          }),

          // border: true,

          meshes: [],
        },  

        ground: {
          keys: [
            'S_DiBan_F02'
          ],

          material: new THREE.MeshStandardMaterial({

            // alphaMap: new THREE.TextureLoader().load( '/static/model/glb/T_DiBan01.png' ),
            map: texture,
            transparent: true,
            // opacity: 0.2,
            // metalness: 0.4
          }),

          mirror: false,
          meshes: [],
        },  
      }
    },

    {
      obj: (process.env.NODE_ENV === 'production' ? '' : '')  + '/static/model/glb/F03.glb',
      mtl: (process.env.NODE_ENV === 'production' ? '' : '')  + '/static/model/glb/F03.mtl',

      rootObj: null,

      children: {

      },

      groups: {
        wall: {
          keys: [
            'S_QiTa_F3__F311'
          ],

          material: new THREE.MeshStandardMaterial({
            color: new THREE.Color("rgb(26, 27, 163)"),
            transparent: true,
            opacity: 0.2,
            metalness: 0.4,
            depthWrite: false, 
          }),


          // border: true,

          meshes: [],
        },  

        ground: {
          keys: [
            'S_DiBan_F03'
          ],

          material: new THREE.MeshStandardMaterial({

            // alphaMap: new THREE.TextureLoader().load( '/static/model/demo/T_DiBan01.png' ),
            map: texture,
            transparent: true,
            depthTest: true,
            // color: new THREE.Color("rgb(26, 27, 163)"),

            // opacity: 0.2,
            // metalness: 0.4
          }),

          mirror: false,
          meshes: [],
        }, 
      }
    }

  ]
}

class SceneFloor extends EventDispatcher {
  constructor (threeInfo, viewer, debug) {
    super();

    this.debug = debug;
    this.threeInfo = threeInfo;
    this.viewer = viewer;
    this.floorThreeInfo = {
      renderer: threeInfo.renderer,
      $dom: viewer
    };
    this.activeIdx = 0;
    this.animationStar = new AnimationStar(this.floorThreeInfo);
    this.init();

    this.mirrorMaterials = [];

    this.pointers = [];

    // debugger;

    store.watch((state, getters) => getters.lightState, (val) => {
      this.switchLight(val);
    });

    store.watch((state, getters) => {
      return getters.tvState;  
    }, (val) => {
      this.switchTv(val);
    });

    store.watch((state, getters) => {
      return getters.cameraState;  
    }, (val) => {
      this.switchCamera(val);
    });

    store.watch((state, getters) => {
      return getters.boxState;  
    }, (val) => {
      this.switchBox(val);
    });

    store.watch((state, getters) => {
      return getters.audioState;  
    }, (val) => {
      this.switchAudio(val);
    });
  }

  switchLight (val) {
    MODEL_PATH.floors[0].groups.light.meshes.forEach((mesh) => {
      mesh.material = val ? LIGHT_ON : LIGHT_OFF;
    });
  }

  switchTv (val) {
    MODEL_PATH.floors[0].groups.tv.meshes.forEach((mesh) => {
      if (val) {
        if (!mesh.default_color) {
          mesh.default_color = mesh.material.color;
        }

        mesh.material.color = LIGHT_ON.color;
      } else {
        mesh.material.color = mesh.default_color;
      }
    });
  }

  switchCamera (val) {
    MODEL_PATH.floors[0].groups.camera.meshes.forEach((mesh) => {
      if (val) {
        if (!mesh.default_color) {
          mesh.default_color = mesh.material.color;
        }

        mesh.material.color = LIGHT_ON.color;
      } else {
        mesh.material.color = mesh.default_color;
      }
    });
  }

  switchAudio (val) {
    // debugger;
    MODEL_PATH.floors[0].groups.audio.meshes.forEach((mesh) => {
      if (val) {
        if (!mesh.default_color) {
          mesh.default_color = mesh.material.color;
        }

        mesh.material.color = LIGHT_ON.color;
      } else {
        mesh.material.color = mesh.default_color;
      }
    });
  }

  switchBox (val) {
    MODEL_PATH.floors[0].groups.box.meshes.forEach((mesh) => {
      if (val) {
        if (!mesh.default_color) {
          mesh.default_color = mesh.material.color;
        }

        mesh.material.color = LIGHT_ON.color;
      } else {
        mesh.material.color = mesh.default_color;
      }
    });
  }

  clearPoint () {
    this.pointers.forEach((pointer) => {
      this.floorThreeInfo.scene.remove(pointer.mesh);
    });

    this.pointers = [];
  }

  addPoint (position, object) {
    const map = new THREE.TextureLoader().load( '/static/model/demo/maps/disc.png' );

    const material = new THREE.SpriteMaterial( {
      map: map,
      color: 0xffff00, 
      fog: true,
      sizeAttenuation: false,
      depthTest: false,
    });

    let sphereInter = new THREE.Sprite(material );
    sphereInter.scale.set(0.03, 0.03, 1);
    sphereInter.position.copy(position);

    let pointer = {
      mesh: sphereInter,
      position: sphereInter.position,
      object_name: object ? object.name : '',
      camera: {
        position: this.cameraControls.getPosition(),
        target: this.cameraControls.getTarget(),
      }  
    };

    this.pointers.push(pointer);

    this.floorThreeInfo.scene.add(sphereInter);

    let event = {
      type: 'pointer',
      data: pointer
    }

    this.dispatchEvent(event);
  }

  init () {
    // 摄像机
    this.floorThreeInfo.camera = new THREE.PerspectiveCamera(60, this.viewer.offsetWidth / this.viewer.offsetHeight, 0.2, 10000);
    this.floorThreeInfo.camera.position.set( 0, 0, 5 );
    
    this.cameraControls = new CameraControls(this.floorThreeInfo.camera, this.viewer);
    this.floorThreeInfo.cameraControls = this.cameraControls;
    this.floorThreeInfo.cameraControls.maxDistance = 100;


    this.floorThreeInfo.scene = new THREE.Scene();  
    // this.floorThreeInfo.fog = new THREE.Fog( 0xffffff, 250, 1400 );

    this.floorThreeInfo.scene.background = new THREE.Color("rgb(0, 0, 22)");

    if (this.debug) {    
      this.measurePoint = new MeasurePoint(this.floorThreeInfo);
      this.measurePoint.addEventListener('point', (event) => {
        this.clearPoint();
        this.addPoint(event.position, event.object);
      });
      this.measurePoint.open();  
    }

    this.initLights();
    this.loadModels();
    this.animationStar.point_effect();
    // this.initHelper();

    window.addEventListener('resize', () => {
      this.onResize();
    });
  }

  onResize () {
    this.floorThreeInfo.camera.aspect = this.viewer.offsetWidth / this.viewer.offsetHeight;
    this.floorThreeInfo.camera.updateProjectionMatrix();
  }

  initHelper () {
    let axisHelper = new THREE.AxesHelper(100);
    this.floorThreeInfo.scene.add(axisHelper);
  }

  initLights () {
    let hemiLight = new THREE.HemisphereLight( 0xddeeff, 0x0f0e0d, 1 );
    this.floorThreeInfo.scene.add( hemiLight );
  }

  loadModels () {
    MODEL_PATH.floors.forEach((floor, idx) => {
      
      let loader = new GLTFLoader();

      loader.load(floor.obj, (ret) => { 
        ret.scene.position.set(6, -1, 0);
        // ret.scale.set(0.01, 0.01, 0.01);

        if (idx == 0) {
          console.log(ret.scene.children);
        }

        this.anaylseGeomerty(ret.scene, floor);
        this.setContent(ret.scene);

        this.measurePoint && this.measurePoint.addTest(ret.scene);
      }, (xhr) => {

      });

    });
  }

  anaylseGeomerty (obj, floorInfo) {
    let children = obj.children.map((item) => {
      return item.children.length ? item.children : item;
    });

    children = children.flat();

    floorInfo.rootObj = obj;
    floorInfo.children = children;

    if (!floorInfo.groups) {
      return;
    }

    Object.keys(floorInfo.groups).forEach((key) => {
      let infoItem = floorInfo.groups[key];

      let meshes = children.filter((item) => {

        return infoItem.keys.indexOf(item.name) > -1;
      });

      infoItem.meshes = meshes;

      if (infoItem.material) {
        meshes.forEach((mesh) => {
          mesh.material = infoItem.material; 
        });
      }

      if (infoItem.materialExt) {
        meshes.forEach((mesh) => {

          Object.keys(infoItem.materialExt).forEach(function (name) {

            mesh.material[name] = infoItem.materialExt[name];
          });

        });
      }

      if (infoItem.border) {
        meshes.forEach((mesh) => {
          this.createBorderEffects(mesh.geometry);
        });
      }

      if (infoItem.mirror) {
        meshes.forEach((mesh) => {

          let mirrorMaterial = new MeshReflectorMaterial(this.threeInfo.renderer, this.floorThreeInfo.camera, 
              this.floorThreeInfo.scene, mesh, {
            mirror: 1,
          });

          mesh.material = mirrorMaterial;

          this.mirrorMaterials.push(mirrorMaterial);
        });
      }
    });
  }

  createBorderEffects (geometry) {
    const edges = new THREE.EdgesGeometry(geometry);
    const line = new THREE.LineSegments(edges, new THREE.LineBasicMaterial( { 
      color: 0x263f61,
      lineWidth: 1 
    }));

    this.floorThreeInfo.scene.add(line);
  }

  setFloorVisble (activeIdx = -1) {
    MODEL_PATH.floors.forEach((item, idx) => {
      if (!item.rootObj) {
        return;
      }

      if (idx == activeIdx || activeIdx == -1) {
        item.rootObj.visible = true;
      } else {
        item.rootObj.visible = false;
      }
    });
  }

  setContent (object) {
    this.floorThreeInfo.scene.add(object);
  }

  setFloor (activeIdx, cameraChange = true, category) {
    this.activeIdx = activeIdx === undefined ? -1 : activeIdx;

    if (cameraChange) {
      this.animateIn(category);  
    } else {
      this.animateInFloor(activeIdx);  
    }

    // 设置category得默认楼层
    if (category) {
      let categoryCamera = CategoryCamera.filter((categoryCamera) => {
        return categoryCamera.ids.indexOf(category.category.id) > -1;
      })[0];
      
      if (categoryCamera && categoryCamera.floor !== undefined) {
        this.activeIdx = categoryCamera.floor;
      }  
    }
     

    let event = {
      type: 'floor',
      data: {
        floor: this.activeIdx
      }
    }

    this.dispatchEvent(event);
  }

  animateInFloor (floor) {
    if (floor == 0) {

      this.cameraControls.setLookAt(
        -9.1, 
        11.4, 
        -2.8, 
        2.22, 
        1.07, 
        -2.7401345081189445, 
        true
      );
    } 

    if (floor == 1) {


      this.cameraControls.setLookAt(
        -7.46, 
        18.51, 
        -5.9, 
        3.3, 
        2.0, 
        -6.0, 
        true
      );

    } 

    if (floor == 2) {


      this.cameraControls.setLookAt(
        -6.2, 
        22, 
        -7.8, 
        6.04, 
        3.19, 
        -7.7, 
        true
      );

    } 

    if (floor == -1) {


      this.cameraControls.setLookAt(
        -15.4, 
        19.7, 
        -2.8, 
        3.48, 
        2.4, 
        -2.7, 
        true
      );

    } 
  }

  animateIn (category) {
    this.cameraControls.setLookAt(5, 5, 5, -1.4, 1.142679676413243, -0.7907392424561693);

    setTimeout(() => {

      // 根据camera找到特定位置
      if (category) {
        let categoryCamera = CategoryCamera.filter((categoryCamera) => {
          return categoryCamera.ids.indexOf(category.category.id) > -1;
        })[0]; 

        if (categoryCamera) {
          this.cameraControls.setLookAt(
            categoryCamera.camera.position[0], 
            categoryCamera.camera.position[1], 
            categoryCamera.camera.position[2],
            categoryCamera.camera.target[0], 
            categoryCamera.camera.target[1], 
            categoryCamera.camera.target[2], true
          );

          return;
        }
      }

      this.cameraControls.setLookAt(
        -9.494575822976282, 
        17.111641318743672, 
        -2.745301981652501, 
        2.228071484812164, 
        1.0749765233618995, 
        -2.7401345081189445, 
        true
      );
    }, 100);
  }

  render () {
    this.setFloorVisble(this.activeIdx);

    const delta = clock.getDelta();
    this.cameraControls.update( delta );
    this.threeInfo.renderer.render(this.floorThreeInfo.scene, this.floorThreeInfo.camera);  
    this.animationStar && this.animationStar.animate();

    this.mirrorMaterials.forEach((mirror) => {
      mirror.update();
    });

    this.measurePoint && this.measurePoint.render();
  }

}

export default SceneFloor;