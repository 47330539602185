var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-details-huiyi"},[_c('div',{staticClass:"info-block-main"},[_c('div',{staticClass:"huiyi-status"},[_vm._v(" 触控一体机 "),_c('div',{class:{
        'huiyi-history': true,
        'huiyi-history-off': _vm.tvState,
      },on:{"click":_vm.onSwitchTv}},[_vm._v(" "+_vm._s(_vm.tvState ? '关闭' : '开启')+"> ")])])]),_c('div',{staticClass:"info-block-main"},[_c('div',{staticClass:"huiyi-status"},[_vm._v(" 壁挂音响 "),_c('div',{class:{
        'huiyi-history': true,
        'huiyi-history-off': _vm.audioState,
      },on:{"click":_vm.onSwitchAudio}},[_vm._v(" "+_vm._s(_vm.audioState ? '关闭' : '开启')+"> ")])])]),_c('div',{staticClass:"info-block-main"},[_c('div',{staticClass:"huiyi-status"},[_vm._v(" 会议摄像麦克风 "),_c('div',{class:{
        'huiyi-history': true,
        'huiyi-history-off': _vm.cameraState,
      },on:{"click":_vm.onSwitchCamera}},[_vm._v(" "+_vm._s(_vm.cameraState ? '关闭' : '开启')+"> ")])])]),_c('div',{staticClass:"info-block-main"},[_c('div',{staticClass:"huiyi-status"},[_vm._v(" 音视频矩阵 "),_c('div',{class:{
        'huiyi-history': true,
        'huiyi-history-off': _vm.boxState,
      },on:{"click":_vm.onSwitchBox}},[_vm._v(" "+_vm._s(_vm.boxState ? '关闭' : '开启')+"> ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }