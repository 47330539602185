<template>
  <div class="info-details-huiyi">
    <div class="info-block-main">
      <div class="huiyi-status">
        照明状态

        <div @click="onSwitch" :class="{
          'huiyi-history': true,
          'huiyi-history-off': lightState,
        }">
          {{ lightState ? '关灯' : '开灯' }}&gt;
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import echarts from 'echarts';
  import { mapGetters } from "vuex";

  export default {
    computed: {
      ...mapGetters(["perWidth", "lightState"]),
    },

    props: {
      useData: {
        type: Object,
        default: null
      },
    },

    mounted () {

    },

    created () {
      
    },

    data () {
      return {
        huiyiSet: {
          available: false,
          show: false,
          list: [],
        }
      }
    },

    methods: {
      onSwitch () {
        this.$store.dispatch('set_lightState', !this.lightState);
      }
    }
  };
</script>

<style lang="less">
  @import "~@/assets/css/mixin.less";

  .info-details-huiyi {
    .huiyi-status {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      padding: 0 10px;
      color: #fff;
      padding: 0 20px;

      .huiyi-history {
        width: 75px;
        height: 34px;
        border: 1px solid #2D3862;
        background: linear-gradient(180deg, #7691F5 0%, #7BD9EC 100%);
        opacity: 1;
        border-radius: 10px;
        line-height: 34px;
        text-align: center;
        cursor: pointer;

        &.huiyi-history-off {
          background: linear-gradient(180deg, #efefef 0%, #cccccc 100%);
          color: #999;
        }
      }

      .huiyi-flag {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        &.huiyi-flag-disabled {
          &:before {
            background: red;
          }
        }

        &:before {
          content: "";
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 4px;
          background: #12B796;
          margin-right: 10px;
        }
      }
    }
  }
  
</style>
