<template>
  <div class="info-details-menjing">
    <div class="info-block-main">
      <div class="info-block-content">
        <div class="info-title">双门电磁锁</div>

        <div class="menjing-status">
          <div @click="onSwitch('open')" :class="{
            'menjing-history': true,
            'menjing-history-off': lightState,
          }">
            开启
          </div>

          <div @click="onSwitch('close')" :class="{
            'menjing-history': true,
            'menjing-history-off': lightState,
          }">
            关闭
          </div>
        </div>        

        <div class="menjing-status">
          <div @click="onSwitch('open_along')" :class="{
            'menjing-history': true,
            'menjing-history-off': lightState,
          }">
            常开
          </div>

          <div @click="onSwitch('close_along')" :class="{
            'menjing-history': true,
            'menjing-history-off': lightState,
          }">
            常关
          </div>
        </div>        
      </div>
    </div>
  </div>
</template>

<script>
  import echarts from 'echarts';
  import { mapGetters } from "vuex";

  export default {
    computed: {
      ...mapGetters(["perWidth", "lightState"]),
    },

    props: {
      useData: {
        type: Object,
        default: null
      },
    },

    mounted () {

    },

    created () {
      
    },

    data () {
      return {
        huiyiSet: {
          available: false,
          show: false,
          list: [],
        }
      }
    },

    methods: {
      onSwitch (key) {
        let url = '';

        if (key == 'open') {
          url = `/api/device/door/open`
        }

        if (key == 'close') {
          url = `/api/device/door/close`
        }

        if (key == 'open_along') {
          url = `/api/device/door/keepOpen`
        }

        if (key == 'close_along') {
          url = `/api/device/door/keepClose`
        }

        $.ajax({
          url: url,
          method: 'post',
          loading: 1,
          success: (ret) => {
            if (ret.code != 'success') {
              return;
            }

            setTimeout(() => {
              this.$Modal.success({
                title: '提示',
                content: '操作成功',
              });
            }, 200);
          }
        });
      }
    }
  };
</script>

<style lang="less">
  @import "~@/assets/css/mixin.less";

  .info-details-menjing {
    .info-block-content {
      padding: 0;
      margin: 0;
    }

    .menjing-status {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      color: #fff;

      .menjing-history {
        &:first-child {
          margin-right: 15px;
        }

        &:hover {
          opacity: 0.8;
        }

        margin-bottom: 10px;
        width: 0;
        flex: 1 1 auto;
        height: 34px;
        border: 1px solid #2D3862;
        background: linear-gradient(180deg, #7691F5 0%, #7BD9EC 100%);
        opacity: 1;
        border-radius: 10px;
        line-height: 34px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  
</style>
