var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-details-menjing"},[_c('div',{staticClass:"info-block-main"},[_c('div',{staticClass:"info-block-content"},[_c('div',{staticClass:"info-title"},[_vm._v("双门电磁锁")]),_c('div',{staticClass:"menjing-status"},[_c('div',{class:{
          'menjing-history': true,
          'menjing-history-off': _vm.lightState,
        },on:{"click":function($event){return _vm.onSwitch('open')}}},[_vm._v(" 开启 ")]),_c('div',{class:{
          'menjing-history': true,
          'menjing-history-off': _vm.lightState,
        },on:{"click":function($event){return _vm.onSwitch('close')}}},[_vm._v(" 关闭 ")])]),_c('div',{staticClass:"menjing-status"},[_c('div',{class:{
          'menjing-history': true,
          'menjing-history-off': _vm.lightState,
        },on:{"click":function($event){return _vm.onSwitch('open_along')}}},[_vm._v(" 常开 ")]),_c('div',{class:{
          'menjing-history': true,
          'menjing-history-off': _vm.lightState,
        },on:{"click":function($event){return _vm.onSwitch('close_along')}}},[_vm._v(" 常关 ")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }