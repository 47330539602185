<template>
  <div class="info-common-wrap">
    <div class="info-block-main">
      <div class="info-block-content">
        <div class="info-title">{{ icon_sub ? icon_sub.name : '' }}</div>


        <div v-if="icon_sub" class="info-block-detail">
          <detail-default :useData="icon_sub"></detail-default>
        </div>
      </div>
    </div>

    <Select  style="width: 100%;" size="large" filterable placeholder="请选择设备" @on-change="onChange" v-model="currentId">
      <Option :value="item.id" v-for="item in showList">{{ item.id }}. {{ item.name }}</Option>
    </Select>

    <div class="info-block-main-bottom">
      <detail-huiyi v-if="icon_sub.category_id == 19"></detail-huiyi>
      <detail-light v-if="icon_sub.category_id == 17"></detail-light>
      <detail-nas v-if="icon_sub.category_id == 23"></detail-nas>
      <detail-menjing v-if="icon_sub.category_id == 12"></detail-menjing>
      <detail-meeting v-if="icon_sub.category_id == 20"></detail-meeting>

      <div class="switch-action">
        固定视角: <i-switch @on-change="onChangeFixed" v-model="fixed"></i-switch>
      </div>
    </div>

  </div>
</template>

<script>
  import { mapGetters } from "vuex";

  import DetailDefault from './details/default';
  import DetailHuiyi from './details/huiyi';
  import DetailLight from './details/light';
  import DetailNas from './details/nas';
  import DetailMenjing from './details/menjing';
  import DetailMeeting from './details/meeting';

  export default {
    components: {
      DetailDefault,
      DetailHuiyi,
      DetailLight,
      DetailNas,
      DetailMenjing,
      DetailMeeting,
    },

    computed: {
      ...mapGetters(["activePointer", 'floor']),

      icon_sub () {
        let subs = [...window.ICON_LIST.map((item) => {
          return item.children;
        })].flat();

        let sub = subs.filter((_sub) => {
          return _sub && _sub.key == this.name;
        })[0];

        return sub;
      },

      showList () {
        return this.list.filter((item) => {
          return this.floor == -1 || (this.floor + 1) == item.floor;
        });
      }
    },

    props: {
      name: {
        type: String,
      }
    },

    data () {
      return {
        summary: {

        },

        list: [],

        currentId: null,
        fixed: false,
      }
    },

    created () {
      this.loadInfo();

      this.$watch('activePointer', () => {
        this.currentId = this.activePointer;
      });

      this.$watch('name', () => {
        this.reset();
        this.loadInfo();
      });

      this.$watch('floor', () => {
        this.$store.dispatch('activePointer', null);
        this.currentId = null;
        this.$emit('point', this.showList); 
      });
    },

    methods: {
      onChangeFixed () {
        this.$store.dispatch('setFixedCamera', this.fixed);
      },

      reset () {
        this.list = [];
        this.currentId = null;
        this.$store.dispatch('activePointer', null);
      },

      onChange () {
        let item = this.list.filter((_item) => {
          return _item.id == this.currentId;
        })[0];

        this.$store.dispatch('activePointer', item ? item.id : null);
      },

      loadInfo () {

        if (!this.icon_sub.category_id) {
          return;
        }

        $.ajax({
          url: '/api/assets',
          data: {
            type: this.icon_sub.category_id
          }
        }).then((ret) => {
          if (ret.code != 'success') {
            return;
          }

          ret.data.forEach((item) => {
            try {
              let jsonPo = JSON.parse(item.position);

              if (jsonPo.position && jsonPo.camera) {
                item.position = jsonPo.position;
                item.camera = jsonPo.camera;  
              }

              this.list.push(item);
              
            } catch (e) {

            }
          });

          this.$emit('point', this.showList);

          // setTimeout(() => {
          //   this.currentId = 1;
          //   this.onChange();
          // }, 100);
        }, () => {

        });


        // setTimeout(() => {
        //   if (this.name == 'wangluo_jisuanji') {

        //     this.list.push({
        //       id: 1,
        //       name: '存储器1',
        //       position:   { "isVector3": true, "x": -2.7435778723839235, "y": 6.310114124698848, "z": -6.1757001876831055 },
        //       camera: {"position":{"isVector3":true,"x":-5.248093205391667,"y":7.091724191869719,"z":-12.701268509439789},"target":{"isVector3":true,"x":-2.640296662627744,"y":5.085683558521966,"z":-3.3307591068643196}},
        //       type: 'wangluoxitong',
        //       avatar: 'https://fan-01.oss-cn-beijing.aliyuncs.com/1656806110812.png'
        //     });

        //     this.list.push({
        //       id: 2,
        //       name: '存储器2',
        //       position:  { "isVector3": true, "x": -9.421799659729004, "y": 11.550804231733057, "z": 0.9608901222323896 },
        //       camera: {"position":{"isVector3":true,"x":-15.862187684618657,"y":11.539571041201544,"z":7.132566786755631},"target":{"isVector3":true,"x":-2.6981606299708103,"y":7.196607948853937,"z":-1.976763524270084}},
        //       type: 'wangluoxitong',
        //       avatar: 'https://fan-01.oss-cn-beijing.aliyuncs.com/1656806110812.png'
        //     });

        //   }

        //   if (this.name == 'anquan_jiankong') {

        //     this.list.push({
        //       id: 1,
        //       name: '枪击1',
        //       position: { "isVector3": true, "x": 1.5681999921798697, "y": 2.0598710949488854, "z": 4.162146915380059 },
        //       camera: {"position":{"isVector3":true,"x":3.0973527247344994,"y":1.9301130318185455,"z":11.539077239883468},"target":{"isVector3":true,"x":1.1342216751056935,"y":-2.247931282453483,"z":-0.43682581419615457}},
        //       type: 'anquan',
        //       avatar: 'https://fan-01.oss-cn-beijing.aliyuncs.com/1656806122163.png'
        //     });

        //     this.list.push({
        //       id: 2,
        //       name: '枪击2',
        //       position:  { "isVector3": true, "x": 1.035372344980646, "y": 2.626916494160753, "z": -0.09570000320672989 },
        //       camera: {"position":{"isVector3":true,"x":-2.2879228231286497,"y":11.745993632028462,"z":10.967310359086415},"target":{"isVector3":true,"x":0.3521123676609886,"y":-2.007113419881781,"z":2.0782667636001824}},
        //       type: 'anquan',
        //       avatar: 'https://fan-01.oss-cn-beijing.aliyuncs.com/1656806122163.png'
        //     });
        //   }

        //   this.$emit('point', this.list);

        //   // setTimeout(() => {
        //   //   this.currentId = 1;
        //   //   this.onChange();
        //   // }, 100);
        // }, 100);
      }
    }
  };
</script>

<style scoped lang="less">
  @import "~@/assets/css/mixin.less";

  .info-common-wrap {

    .info-block-detail {
      padding: 0 10px;
      margin-top: 20px;
      color: #fff;
    }    

    .switch-action {
      right: 30px;
      bottom: 30px;
      z-index: 11;
      position: fixed;
      color: #fff;
    }

    .info-select-bottom {
      margin-top: 10px;
    }

    .info-block-main-bottom {
      margin-top: 20px;
    }
  }
  
</style>
