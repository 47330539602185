<template>
  <Layout class="full">
    <div class="page-layout-wrap">
      <router-view></router-view>
    </div>
  </Layout>
</template>

<script>
import { mapGetters } from "vuex";
import user from "@/assets/user";

export default {
  computed: {
    ...mapGetters(["user"])
  },

  methods: {
    logout() {
      user.clear();

      this.$router.push({
        name: "account_login"
      });
    },

    onSubmit () {
      let data = $.extend({}, this.projectUser);

      if (!data.password) {
        delete data.password;
      }

      if (!data.new_passowrd) {
        delete data.new_passowrd;
      }

      $.ajax({
        url: '/pc/user/update_my',
        loading: 1,
        data: data,
        fullError: 1,
        type: 'post',
      }).then((ret) => {
        if (ret.code) {
          return;
        }

        this.projectUser.show = false;

        user.getInfo(true).then((ret) => {
          this.$store.dispatch('set_user', ret);
        });

        this.$nextTick(() => {
          this.$Message.info('更新成功');
        });

      }, () => {

      });
    },

    cancel() {
      this.$Message.info("取消");
    }
  },

  created () {

  },

  data () {
    return {
      editing: false,
      edit_data: {
        new_password: "",
        old_password: ""
      },

      projectUser: {
        name: '',
        show: false,
        avatar: '',
        old_password: '',
        new_password: '',
      }
    };
  }
};
</script>

<style lang="less">
@import "~@/assets/css/mixin.less";

  .custom-scroll {
    overflow: auto;

    
    &.custom-scroll-red {
      &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        box-shadow   : inset 0 0 1px rgba(0, 0, 0, 0);
        // background   : @partyMain;
      }  
    }

    &::-webkit-scrollbar {
      width : 15px;  /*高宽分别对应横竖滚动条的尺寸*/
      height: 1px;
    }
    
    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      box-shadow   : inset 0 0 1px rgba(0, 0, 0, 0);
      background   : #11a3d0;
    }

    &::-webkit-scrollbar-track {
      box-shadow   : inset 0 0 15px rgba(0, 0, 0, 0);
      border-radius: 15px;
      background   : none;
    }
  }

  .custom-scroll-less {
    overflow: auto;

    
    &.custom-scroll-red {
      &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        box-shadow   : inset 0 0 1px rgba(0, 0, 0, 0);
        // background   : @partyMain;
      }  
    }

    &::-webkit-scrollbar {
      width : 5px;  /*高宽分别对应横竖滚动条的尺寸*/
      height: 1px;
    }
    
    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      box-shadow   : inset 0 0 1px rgba(0, 0, 0, 0);
      background   : #11a3d0;
    }

    &::-webkit-scrollbar-track {
      box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0);
      border-radius: 5px;
      background   : none;
    }
  }

  .left-span {
    margin-right: 10px;
  }
  .class-edit {
    margin-bottom: 15px;
  }
  .upload {
    background: #ffffff;
  }

  .layout-logo {
    color: #fff;
    font-size: 22px;

    img {
      height: 60px;
      margin-top: 10px;
      margin-left: 20px;
    }
  }

  .main-header {
    height: 100%;

    .layout-tip {
      color: #999;
      float: right;
      height: 80px;
      line-height: 80px;
      font-size: 15px;
      padding-right: 15px;
    }

    .layout-nav {
      float: right;
      width: 220px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-left: 1px solid @borderColor;

      .nav-link {
        display: block;
        text-decoration: none;
        color: #333;
      }

      .drop-item {
        width: 120px;
      }
    }
  }

  .avator-box{
    position: absolute;
    right: 0px;
    top:9px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    img{
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  .author-create-modal {
    .author-form-wrap {
      width: 380px;
      margin: 20px auto;

      .label-item {
        width: 90px;
      }
    }
  }

  .page-layout-wrap {
    height: 0;
    flex: 1 1 auto;
  }

  
</style>
