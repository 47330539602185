import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    shop: null,
    routeName: '',

    activePointer: null,
    activeLive: null,

    floor: -1,

    perWidth: window.innerWidth / 100,
    lightState: false,

    audioState: false,
    tvState: false,
    cameraState: false,
    boxState: false,

    fixedCamera: false,
  },

  mutations: {
    set_user (state, user) {
      state.user = user;
    },

    set_floor (state, floor) {
      state.floor = floor;
    },

    set_shop (state, shop) {
      state.shop = shop;
    },

    set_routeName (state, routeName) {
      state.routeName = routeName;
    },

    set_lightState (state, light) {
      state.lightState = light;
    },

    set_audioState (state, audio) {
      state.audioState = audio;
    },

    set_boxState (state, box) {
      state.boxState = box;
    },

    set_tvState (state, tv) {
      state.tvState = tv;
    },

    set_cameraState (state, camera) {
      state.cameraState = camera;
    },

    activePointer (state, pointer) {
      state.activePointer = pointer;
    },

    activeLive (state, live) {
      state.activeLive = live;
    },

    setFixedCamera (state, fixed) {
      state.fixedCamera = fixed;
    },
  },

  actions: {
    set_user (context, user) {
      context.commit('set_user', user);
    },

    set_shop (context, shop) {
      context.commit('set_shop', shop);
    },

    set_floor (context, floor) {
      context.commit('set_floor', floor);
    },

    set_lightState (context, lightState) {
      context.commit('set_lightState', lightState);
    },

    set_cameraState (context, cameraState) {
      context.commit('set_cameraState', cameraState);
    },

    set_boxState (context, boxState) {
      context.commit('set_boxState', boxState);
    },

    set_audioState (context, audioState) {
      context.commit('set_audioState', audioState);
    },

    set_tvState (context,_tvState) {
      context.commit('set_tvState',_tvState);
    },

    set_routeName (context, routeName) {
      context.commit('set_routeName', routeName);
    },

    activePointer (context, pointer) {
      context.commit('activePointer', pointer);
    },

    activeLive (context, live) {
      context.commit('activeLive', live);
    },

    setFixedCamera (context, fixed) {
      context.commit('setFixedCamera', fixed);
    },
  },

  getters: {
    user (state) {
      return state.user;
    },

    shop (state) {
      return state.shop;
    },

    routeName (state) {
      return state.routeName;
    },

    activePointer (state) {
      return state.activePointer;
    },

    activeLive (state) {
      return state.activeLive;
    },

    perWidth (state) {
      return state.perWidth;
    },

    lightState (state) {
      return state.lightState;
    },

    cameraState (state) {
      return state.cameraState;
    },

    audioState (state) {
      return state.audioState;
    },

    boxState (state) {
      return state.boxState;
    },

    tvState (state) {
      return state.tvState;
    },

    floor (state) {
      return state.floor;
    },

    fixedCamera (state) {
      return state.fixedCamera;
    }
  }
});