<template>
  <div class="info-details-huiyi">
    <div class="info-block-main">
      <div class="huiyi-status">
        触控一体机

        <div @click="onSwitchTv" :class="{
          'huiyi-history': true,
          'huiyi-history-off': tvState,
        }">
          {{ tvState ? '关闭' : '开启' }}&gt;
        </div>
      </div>
    </div>

    <div class="info-block-main">
      <div class="huiyi-status">
        壁挂音响

        <div @click="onSwitchAudio" :class="{
          'huiyi-history': true,
          'huiyi-history-off': audioState,
        }">
          {{ audioState ? '关闭' : '开启' }}&gt;
        </div>
      </div>
    </div>

    <div class="info-block-main">
      <div class="huiyi-status">
        会议摄像麦克风

        <div @click="onSwitchCamera" :class="{
          'huiyi-history': true,
          'huiyi-history-off': cameraState,
        }">
          {{ cameraState ? '关闭' : '开启' }}&gt;
        </div>
      </div>
    </div>


    <div class="info-block-main">
      <div class="huiyi-status">
        音视频矩阵

        <div @click="onSwitchBox" :class="{
          'huiyi-history': true,
          'huiyi-history-off': boxState,
        }">
          {{ boxState ? '关闭' : '开启' }}&gt;
        </div>
      </div>
    </div>


  </div>
</template>

<script>
  import echarts from 'echarts';
  import { mapGetters } from "vuex";

  export default {
    computed: {
      ...mapGetters(["perWidth", "tvState", "audioState", "cameraState", "boxState"]),
    },

    props: {
      useData: {
        type: Object,
        default: null
      },
    },

    mounted () {

    },

    created () {
      
    },

    data () {
      return {
        
      }
    },

    methods: {
      onSwitchTv () {
        this.$store.dispatch('set_tvState', !this.tvState);
      },

      onSwitchAudio () {
        this.$store.dispatch('set_audioState', !this.audioState);
      },

      onSwitchCamera () {
        this.$store.dispatch('set_cameraState', !this.cameraState);
      },

      onSwitchBox () {
        this.$store.dispatch('set_boxState', !this.boxState);
      }
    }
  };
</script>

<style lang="less">
  @import "~@/assets/css/mixin.less";

  .info-details-huiyi {
    .huiyi-status {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      padding: 0 10px;
      color: #fff;
      padding: 0 20px;

      .huiyi-history {
        width: 75px;
        height: 34px;
        border: 1px solid #2D3862;
        background: linear-gradient(180deg, #7691F5 0%, #7BD9EC 100%);
        opacity: 1;
        border-radius: 10px;
        line-height: 34px;
        text-align: center;
        cursor: pointer;

        &.huiyi-history-off {
          background: linear-gradient(180deg, #efefef 0%, #cccccc 100%);
          color: #999;
        }
      }

      .huiyi-flag {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        &.huiyi-flag-disabled {
          &:before {
            background: red;
          }
        }

        &:before {
          content: "";
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 4px;
          background: #12B796;
          margin-right: 10px;
        }
      }
    }
  }
  
</style>
