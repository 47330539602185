<template>
  <div class="info-details-wangluo">
    <div class="pie-wrap">
      <div  ref="left" class="pie-left">
        left
      </div>

      <div class="pie-right">
        <div ref="top" class="pie-right-top">top</div>
        <div ref="bottom" class="pie-right-bottom">bottom</div>
      </div>
    </div>
  </div>
</template>

<script>
  import echarts from 'echarts';
  import { mapGetters } from "vuex";

  export default {
    computed: {
      ...mapGetters(["perWidth"]),
    },

    props: {
      useData: {
        type: Object,
        default: null
      },
    },

    mounted () {
      this.initLeftChart();
      this.initTopChart();
      this.initBottomChart();
    },

    created () {
      this.$watch('useData', () => {
        this.initLeftChart();
        this.initTopChart();
        this.initBottomChart();
      });

      $(window).resize(() => {
        Object.keys(this.echartSet).forEach((key) => {
          this.echartSet[key].resize();
        });
      });
    },

    data () {
      return {
        echartSet: {
          left: null,
          top: null,
          bottom: null,
        }
      }
    },

    methods: {
      initTopChart () {
        let safeOption = {
          grid: {
            show: false,
            top: '3%',
            left: '3%',
            right: '3%',
            bottom: '3%',
          },

          series: [
            {
              name: '访问来源',
              type: 'pie',
              radius: ['70%', '85%'],
              center: ['50%', '50%'],

              labelLine: {
                show: false
              },

              // emphasis: {
              //   label: {
              //     show: true,
              //     fontSize: '40',
              //     fontWeight: 'bold'
              //   }
              // },

              label: {
                show: true,
                position: 'center',
                formatter: () => {
                  return [
                    `{a|${this.useData.category.online}}`,
                    '{b|在线}'
                  ].join('\n');
                },

                rich: {
                  a: {
                    color: '#7691F5',
                    fontSize: this.perWidth * 1.3
                  },

                  b: {
                    color: '#fff',
                    fontSize: this.perWidth * 0.8
                  }
                }
              },

              data: [
                {
                  value: this.useData.category.online,
                  name: '在线',
                  itemStyle: {
                    color: '#7691F5'
                  }
                }
              ]
            }
          ]
        };

        this.echartSet.top = echarts.init(this.$refs.top);
        this.echartSet.top.setOption(safeOption, false);
        this.echartSet.top.resize();
      },

      initBottomChart () {
        let safeOption = {
          grid: {
            show: false,
            top: '3%',
            left: '3%',
            right: '3%',
            bottom: '3%',
          },

          series: [
            {
              name: '访问来源',
              type: 'pie',
              radius: ['70%', '85%'],
              center: ['50%', '50%'],

              labelLine: {
                show: false
              },

              // emphasis: {
              //   label: {
              //     show: true,
              //     fontSize: '40',
              //     fontWeight: 'bold'
              //   }
              // },

              label: {
                show: true,
                position: 'center',
                formatter: () => {
                  return [
                    `{a|${this.useData.category.total - this.useData.category.online}}`,
                    '{b|离线}'
                  ].join('\n');
                },

                rich: {
                  a: {
                    color: '#7691F5',
                    fontSize: this.perWidth * 1.3
                  },

                  b: {
                    color: '#fff',
                    fontSize: this.perWidth * 0.8
                  }
                }
              },

              data: [
                {
                  value: this.useData.category.total - this.useData.category.online,
                  name: '离线',
                  itemStyle: {
                    color: '#151D38'
                  }
                }
              ]
            }
          ]
        };

        this.echartSet.bottom = echarts.init(this.$refs.bottom);
        this.echartSet.bottom.setOption(safeOption, false);
        this.echartSet.bottom.resize();
      },

      initLeftChart () {
        let safeOption = {
          grid: {
            show: false,
            top: '3%',
            left: '3%',
            right: '3%',
            bottom: '3%',
          },

          series: [
            {
              name: '访问来源',
              type: 'pie',
              radius: ['70%', '85%'],
              center: ['50%', '50%'],

              labelLine: {
                show: false
              },

              // emphasis: {
              //   label: {
              //     show: true,
              //     fontSize: '40',
              //     fontWeight: 'bold'
              //   }
              // },

              label: {
                show: true,
                position: 'center',
                formatter: () => {
                  return [
                    `{a|${this.useData.category.total}}`,
                    '{b|运行设备}'
                  ].join('\n');
                },

                rich: {
                  a: {
                    color: '#7691F5',
                    fontSize: this.perWidth * 2
                  },

                  b: {
                    color: '#fff',
                    fontSize: this.perWidth * 0.7
                  }
                }
              },

              data: [
                {
                  value: this.useData.category.online,
                  name: '在线',
                  itemStyle: {
                    color: '#7691F5'
                  }
                },
                {
                  value: this.useData.category.total - this.useData.category.online,
                  name: '离线',
                  itemStyle: {
                    color: '#151D38'
                  }
                }
              ]
            }
          ]
        };

        this.echartSet.left = echarts.init(this.$refs.left);
        this.echartSet.left.setOption(safeOption, false);
        this.echartSet.left.resize();
      }  
    }
  };
</script>

<style lang="less">
  @import "~@/assets/css/mixin.less";

  .info-details-wangluo {
    .pie-wrap {
      display: flex;

      .pie-left {
        width: 190px;
        height: 190px;
      }

      .pie-right {
        width: 114px;
        height: 190px;

        .pie-right-top {
          height: 95px;
        }

        .pie-right-bottom {
          height: 95px;
        }
      }
    }
  } 
  
</style>
