import cookie from '@/assets/cookie';
import store from '../store'

let user = {
  _userInfo: null,
  
  clear: () => {
    cookie.remove('BITE_LOGIN_TOKEN');
    store.dispatch('set_user', null);

    cookie.remove('BITE_SHOP');
    cookie.remove('set_shop', null);

    // this._userInfo = null;
  },

  clearUser: () => {
    this._userInfo = null;
  },

  isCompany: () => {
    return true;
  },

  getInfo: (clear) => {
    return new Promise((reslove, reject) => {
      let BITE_LOGIN_TOKEN = cookie.get('BITE_LOGIN_TOKEN');

      if (!BITE_LOGIN_TOKEN) {
        reject();
      } else {
        // 如果有缓存
        let userInfo = user.getUserInfo();

        if (userInfo && !clear) {
          reslove(userInfo);
          return;
        }

        $.ajax({
          url: '/api/auth/profile',
        }).then((ret) => {
          if (ret.code == 'success') {
            user.saveUserInfo(ret.data);
            reslove(ret.data);
          } else {
            reject();
          }
        }, () => {
          reject();
        });
      }
    });
  },

  getToken: () => {
    return cookie.get('BITE_LOGIN_TOKEN');
  },

  setToken: (token) => {
    cookie.set('BITE_LOGIN_TOKEN', token);
  },

  getShop: () => {
    return cookie.get('BITE_SHOP');
  },

  setShop: (shop) => {
    cookie.set('BITE_SHOP', shop.id);
  },

  saveUserInfo: (ret) => {
    user._userInfo = ret;
    user._time = +new Date();
  },

  getUserInfo: () => {
    if (user._userInfo && (+new Date - user._time < 1000 * 60)) {
      return user._userInfo;
    } else {
      user._userInfo = null;
      return false;
    }
  }
}

export default user;