<template>
  <div @wheel.stop="onWheel" class="pointer-2d-detail custom-scroll">
    <div class="pointer-title">
      <div class="pointer-name">
        {{ pointer.id }} . {{ pointer.name }} 
      </div>
      <div class="pointer-status">
        <span class="pointer-status-block"></span>

        在线
      </div>
    </div>

    <div class="pointer-content">
      <div class="pointer-content-item">
        <div class="pointer-content-label">品牌：{{ pointer.brand }}  <span>型号：{{ pointer.model }}</span></div>
      </div>
      <div class="pointer-content-item">
        <div class="pointer-content-label">设备用途：{{ pointer.purpose }}</div>
      </div>

      <div class="pointer-content-item pointer-content-item-description">
        <p v-for="item in pointer.extra">{{ item }}</p>
      </div>
    </div>

    <div class="pointer-action">
      <div @click="onLive" v-if="pointer.type == 7 && pointer.id != 2" class="action-live">
        查看监控 &gt;
      </div>

      <div @click="onOpen" v-if="pointer.id == 12" class="action-live">
        开门 &gt;
      </div>

    </div>

  </div>
</template>

<script>
  export default {
    props: {

      pointer: {
        type: Object,
        default: null
      }
    },

    data () {
      return {
        videoSet: {
          show: false,
        }
      }
    },

    methods: {
      onOpen () {
        $.ajax({
          url: '/api/device/door/open',
          method: 'post',
          loading: 1,
          success: (ret) => {
            if (ret.code != 'success') {
              return;
            }

            this.$Modal.success({
              title: '提示',
              content: '开门成功!'
            });
          }
        });
      },

      onWheel () {
        console.log('wehll');
      },

      onLive () {
        this.$store.dispatch('activeLive', {
          id: this.pointer.id
        });
      },  

      onClick () {
        this.$emit('on-click'); 
      }
    }
  };
</script>

<style lang="less">
  @import "~@/assets/css/mixin.less";

  .pointer-2d-detail {
    padding: 15px;
    font-size: 12px;
    height: 100%;
    padding-bottom: 50px;

    .pointer-title {
      border-bottom: 1px dashed #2D3662;
      padding: 5px 0;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;      

      .pointer-status {
        .pointer-status-block {
          display: inline-block;
          margin-right: 10px;
          width: 10px;
          height: 10px;
          border-radius: 10px;
          background: #12B796;
        }
      }
    }


    .pointer-content {
      .pointer-content-item {
        display: flex;
        margin-bottom: 10px;

        &.pointer-content-item-description {
          display: block;

          p {
            display: block;
            background: #2B3765; 
            margin-bottom: 10px;
            padding: 5px;
            border-radius: 5px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        .pointer-content-label {
          flex: 1 1 auto;
          text-align: left;

          span {
            margin-left: 20px;
          }
        }
      }
    }

    .pointer-action {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 10px 15px;

      .action-live {
        .btn(100%, 34px, 12px, #7BD9EC, #fff, rgba(0, 0, 0, 0), 20px);  
        background: linear-gradient(180deg, #7691F5 0%, #7BD9EC 100%);
        cursor: pointer;
      }
    }
  }
  
</style>
