var Cookie = {
    _isValidKey : function (key) {
        return (new RegExp("^[^\\x00-\\x20\\x7f\\(\\)<>@,;:\\\\\\\"\\[\\]\\?=\\{\\}\\/\\u0080-\\uffff]+\x24")).test(key);
    },

    getRaw :function (key) {
        var self = this;
        if (self._isValidKey(key)) {
            var reg = new RegExp("(^| )" + key + "=([^;]*)(;|\x24)"),
                result = reg.exec(document.cookie);
                
            if (result) {
                return result[2] || null;
            }
        }

        return null;
    },

     
    get : function (key) {
        var self = this;
        var value = self.getRaw(key);
        if ('string' == typeof value) {
            value = decodeURIComponent(value);
            return value;
        }
        return null;
    },

    setRaw : function (key, value, options) {
        var self = this;
        if (!self._isValidKey(key)) {
            return;
        }
        
        options = options || {};
        //options.path = options.path || "/"; // meizz 20100402 设定一个初始值，方便后续的操作
        //berg 20100409 去掉，因为用户希望默认的path是当前路径，这样和浏览器对cookie的定义也是一致的
        
        // 计算cookie过期时间
        var expires = options.expires;
        if ('number' == typeof options.expires) {
            expires = new Date();
            expires.setTime(expires.getTime() + options.expires);
        }
        
        document.cookie =
            key + "=" + value
            + (options.path ? "; path=" + options.path : "")
            + (expires ? "; expires=" + expires.toGMTString() : "")
            + (options.domain ? "; domain=" + options.domain : "")
            + (options.secure ? "; secure" : ''); 
    },

    remove : function (key, options) {
        var self = this;
        options = options || {};
        options.expires = new Date(0);
        self.setRaw(key, '', options);
    },

    set : function (key, value, options) {
        var self = this;
        self.setRaw(key, encodeURIComponent(value), options);
    },

    cookie: function (jsmod) {
        var self = this;
        self.getRaw();
        jsmod.cookie = {
            getRaw: self.getRaw(),
            get: self.get(),
            remove: self.remove(),
            setRaw: self.setRaw(),
            set: self.set()
        };
    }

}

export default Cookie;
