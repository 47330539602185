<template>
  <transition name="page">
    <div class="model-base-layout">

      <div v-if="liveSet.data">
        <live ref="live" :liveData="liveSet.data" v-model="liveSet.show"></live>
      </div>

      <div class="model-base-title">
        <div class="base-logo">
          <img src="./images/home_logoNew.png"/>
        </div>

        <div class="base-name">
<!--          之年智能楼宇IBMS三维平台-->
          智慧园区数字孪生物联管控平台

          <div class="base-name-tip">
<!--            Zhi Nian Intelligent Building IBMS 3D Platform-->
            SMART PARK IOT CONTROL PLATFORM
          </div>
<!--          判断只有首页时，才展示楼层导航和模型全景-->
          <div @click="onSwitchFloorModel" class="base-action-floor" v-if="sceneSet.current == 'home'">
               {{ currentModelState == 'building' ? '楼层导航' : '模型全景' }}
          </div>



        </div>

        <div class="base-time">
          {{ timeStr }}
        </div>
      </div>

      <div  class="model-base-bottom">
        <div class="bottom-icon-wrap">
          <div v-for="icon, idx in icons"  :class="`icon-item ${infoSet.current == icon.key ? 'icon-item-active' : ''} icon-item-${icon.key}`"
            @click="icon.isHome ? onHome('home') : onMainIcon(icon)" >
            <div class="icon-image">
              <img v-if="infoSet.current == icon.key" :src="icon.icon_active"/>
              <img v-else :src="icon.icon"/>
            </div>
            <div class="icon-title">{{ icon.name }}</div>

            <div v-if="icon.children && icon.children.length && infoSet.current == icon.key" class="icon-children">
              <div v-for="child in icon.children" @click.stop="onMainIconSub(child)" :class="{
                'icon-children-item': true,
                'icon-children-item-canuse': child.canuse,
                'icon-children-item-active': child.key == infoSet.current_sub,
              }">
                <img :src="child.key == infoSet.current_sub ? child.icon_active : child.icon"/>
                <div class="icon-child-title">{{ child.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div  v-if="sceneSet.current == 'floor'&&(currentModelState=='building'||currentModelState=='floor')" class="model-base-right">
        <div class="right-icon-wrap" >
          <div @click="onFloor(-1)" :class="{
            'icon-item': true,
            'icon-item-all': true,
            'icon-item-active': infoSet.currentFloor == -1,
          }">
            ALL
          </div>

          <div @click="onFloor(0)" :class="{
            'icon-item': true,
            'icon-item-1f': true,
            'icon-item-active': infoSet.currentFloor == 0,
          }">
            1F
          </div>


          <div @click="onFloor(1)" :class="{
            'icon-item': true,
            'icon-item-2f': true,
            'icon-item-active': infoSet.currentFloor == 1,
          }">
            2F
          </div>

          <div @click="onFloor(2)" :class="{
            'icon-item': true,
            'icon-item-3f': true,
            'icon-item-active': infoSet.currentFloor == 2,
          }">
            3F
          </div>
        </div>
      </div>
<!--      <div  v-if="currentModelState == 'floor'&&currentModelState=='building'" class="model-base-right">-->
<!--        <div class="right-icon-wrap">-->
<!--          <div @click="setFloorVisble(-1)" :class="{-->
<!--            'icon-item': true,-->
<!--            'icon-item-all': true,-->
<!--            'icon-item-active': currentModelFloor  == -1,-->
<!--          }">-->
<!--            ALL-->
<!--          </div>-->

<!--          <div @click="setFloorVisble(0)" :class="{-->
<!--            'icon-item': true,-->
<!--            'icon-item-1f': true,-->
<!--            'icon-item-active': currentModelFloor  == 0,-->
<!--          }">-->
<!--            1F-->
<!--          </div>-->


<!--          <div @click="setFloorVisble(1)" :class="{-->
<!--            'icon-item': true,-->
<!--            'icon-item-2f': true,-->
<!--            'icon-item-active': currentModelFloor  == 1,-->
<!--          }">-->
<!--            2F-->
<!--          </div>-->

<!--          <div @click="setFloorVisble(2)" :class="{-->
<!--            'icon-item': true,-->
<!--            'icon-item-3f': true,-->
<!--            'icon-item-active': currentModelFloor  == 2,-->
<!--          }">-->
<!--            3F-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

      <div ref="viewer" class="model-content">

      </div>

      <div ref="info" class="model-info-set">
        <info-common v-if="infoSet.components == 'common'" :name="infoSet.current_sub" @point="onAddPoints"></info-common>
        <info-home  @summary="onSummary" v-if="sceneSet.current == 'home'"></info-home>
      </div>

      <div class="model-pointer-wrap">
        <pointer-main @ready="onPointerReady" ref="pointer_main"></pointer-main>
      </div>

      <div class="pointer-data" v-if="sceneSet.pointerData">
        <div>
          位置: {{ sceneSet.pointerData.position }}
        </div>

        <div>
          相机: {{ JSON.stringify(sceneSet.pointerData.camera) }}
        </div>

        <div>
          模型: {{ sceneSet.pointerData.object_name }}
        </div>


        <div>
          ID: <Input style="width: 100px;"  v-model="sceneSet.id"></Input>

          <Button type="primary" @click="onPositionUpdate">更新</Button>
        </div>
      </div>

      <div v-if="false" class="scene-action-wrap">
        <div class="scene-action-item">
          <span>{{ infoSet.current_sub }}</span>
          <Button @click="onScene('home')">外壳</Button>
          <Button @click="onScene('floor', -1)">楼体</Button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import moment from 'moment'
  import * as THREE from 'three';
  import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

  import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
  import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js';
  import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
  import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js';
  import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js'
  import { ReflectorForSSRPass } from 'three/examples/jsm/objects/ReflectorForSSRPass.js';
  import { Water } from 'three/examples/jsm/objects/Water.js';
  import { Lensflare, LensflareElement } from 'three/examples/jsm/objects/Lensflare.js';

  import MeshReflectorMaterial from '@/assets/three/MeshReflectorMaterial.js';

  import AnimationRoad from './render/animations/road'
  import { Fire } from './render/animations/fire'
  import { Sky } from 'three/examples/jsm/objects/Sky.js';


  // import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
  // import { SSRPass } from "three/examples/jsm/postprocessing/SSRPass.js";

  import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
  import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
  import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
  import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
  import { SSAARenderPass } from 'three/examples/jsm/postprocessing/SSAARenderPass.js';

  import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader.js';
  import { CopyShader } from 'three/examples/jsm/shaders/CopyShader.js';
  import { PixelShader } from 'three/examples/jsm/shaders/PixelShader.js';
  import CameraControls from 'camera-controls';
  import SceneFloor from './render/scene/floor.js';
  import MessurePoint from './render/tools/measurePoint.js'

  import PointerMain from './render/pointers/main'
  import InfoCommon from './render/infos/common'
  import InfoHome from './render/infos/home'
  import { mapGetters } from "vuex";

  import { nodeFrame } from 'three/examples/jsm/renderers/webgl/nodes/WebGLNodes.js';
  import { TeapotGeometry } from 'three/examples/jsm/geometries/TeapotGeometry.js';
  import { MeshLine, MeshLineMaterial, MeshLineRaycast } from 'three.meshline';

  import * as Nodes from 'three/examples/jsm/nodes/Nodes.js';
  import Live from '../../components/live';

  const ICON_LIST = window.ICON_LIST = [
    {
      key: 'home',
      name: '主界面',
      icon: require('./images/icons/shouyeNew.png'),
      icon_active: require('./images/icons/shouye_activeNew.png'),
      isHome: true,

      // children: [

        // {
        //   key: 'anquan_jiankong',
        //   name: '视频监控',
        //   scene: 'floor',
        //   components: 'common',
        //   category_id: 7,
        //   icon: require('./images/icons/anquan_jiankong.png'),
        //   icon_active: require('./images/icons/anquan_jiankong_active.png'),
        // },

        // {
        //   key: 'bangong_cunchu',
        //   name: '网络存储',
        //   icon: require('./images/icons/bangong_cunchu.png'),
        //   icon_active: require('./images/icons/bangong_cunchun_active.png'),
        //
        //   scene: 'floor',
        //   components: 'common',
        //   category_id: 23,
        // },


        // {
        //   key: 'anquan_ruqing',
        //   name: '入侵报警',
        //   icon: require('./images/icons/anquan_ruqing.png'),
        //   icon_active: require('./images/icons/anquan_ruqing_active.png'),
        //   scene: 'floor',
        //   components: 'common',
        //   category_id: 9,
        // },

        //
        // {
        //   key: 'anquan_tingche',
        //   name: '停车管理',
        //   icon: require('./images/icons/anquan_tingche.png'),
        //   icon_active: require('./images/icons/anquan_tingche_active.png'),
        //   scene: 'floor',
        //   components: 'common',
        //   category_id: 13,
        // },

        // {
        //   key: 'anquan_yiqing',
        //   name: '疫情防控',
        //   icon: require('./images/icons/anquan_yiqing.png'),
        //   icon_active: require('./images/icons/anquan_yiqing_active.png'),
        //   scene: 'floor',
        //   components: 'common',
        //   category_id: 14,
        // },

        // {
        //   key: 'anquan_duijiang',
        //   name: '可视对讲',
        //   icon: require('./images/icons/anquan_menjing.png'),
        //   icon_active: require('./images/icons/anquan_menjing_active.png'),
        //   scene: 'floor',
        //   components: 'common',
        //   category_id: 11,
        // },

        // {
        //   key: 'bangong_xinxi',
        //   name: '信息发布',
        //   icon: require('./images/icons/bangong_xinxi.png'),
        //   icon_active: require('./images/icons/bangong_xinxi_active.png'),
        //
        //   scene: 'floor',
        //   components: 'common',
        //   category_id: 22,
        // },

        // {
        //   key: 'anquan_menjing',
        //   name: '门禁管理',
        //   icon: require('./images/icons/anquan_menjing.png'),
        //   icon_active: require('./images/icons/anquan_menjing_active.png'),
        //   scene: 'floor',
        //   components: 'common',
        //   category_id: 12,
        // },


      // ]
    },

    {
      key: 'wangluo',
      name: '网络通讯',
      icon: require('./images/icons/wangluoNew.png'),
      icon_active: require('./images/icons/wangluo_activeNew.png'),

      children: [
        {
          key: 'wangluo_jisuanji',
          scene: 'floor',
          name: '计算机网络',
          components: 'common',
          icon: require('./images/icons/wangluo_jisuanjiNew.png'),
          icon_active: require('./images/icons/wangluo_jisuanji_active.png'),
          category_id: 5,
        },

        {
          key: 'wangluo_dianhua',
          name: '电话交换',
          scene: 'floor',
          components: 'common',
          icon: require('./images/icons/wangluo_dianhuaNew.png'),
          icon_active: require('./images/icons/wangluo_dianhua_active.png'),
          category_id: 6,
        }
      ]
    },

    {
      key: 'loukong',
      name: '楼控系统',
      icon: require('./images/icons/longkongNew.png'),
      icon_active: require('./images/icons/loukong_activeNew.png'),

      children: [
        {
          key: 'loukong_jianzhu',
          name: '建筑设备',
          icon: require('./images/icons/loukong_jianzhuNew.png'),
          icon_active: require('./images/icons/loukong_jianzhu_activeNew.png'),
          scene: 'floor',
          components: 'common',
          category_id: 15,
        },

        {
          key: 'loukong_nengyuan',
          name: '能源管理',
          icon: require('./images/icons/loukong_nengyuanNew.png'),
          icon_active: require('./images/icons/loukong_nengyuan_activeNew.png'),

          scene: 'floor',
          components: 'common',
          category_id: 16,
        },
        {
          key: 'loukong_zhaoming',
          name: '智能照明',
          icon: require('./images/icons/loukong_zhaomingNew.png'),
          icon_active: require('./images/icons/loukong_zhaoming_activeNew.png'),

          scene: 'floor',
          components: 'common',
          category_id: 17,
        },

        {
          key: 'loukong_huanjing',
          name: '环境监测',
          icon: require('./images/icons/loukong_huanjingNew.png'),
          icon_active: require('./images/icons/loukong_huanjing_activeNew.png'),
          scene: 'floor',
          components: 'common',
          category_id: 18,
        },



      ]
    },

    {
      key: 'bangong',
      name: '智能办公',
      icon: require('./images/icons/bangongNew.png'),
      icon_active: require('./images/icons/bangong_activeNew.png'),


      children: [
        {
          key: 'bangong_huiyi',
          name: '智能会议',
          icon: require('./images/icons/bangong_huiyiNew.png'),
          icon_active: require('./images/icons/bangong_huiyi_activeNew.png'),

          scene: 'floor',
          components: 'common',
          category_id: 19,
        },

        {
          key: 'bangong_shiping',
          name: '视频会议',
          icon: require('./images/icons/bangong_shipingNew.png'),
          icon_active: require('./images/icons/bangong_shiping_activeNew.png'),

          scene: 'floor',
          components: 'common',
          category_id: 20,
        },
        {
          key: 'bangong_guangbo',
          name: '公共广播',
          icon: require('./images/icons/bangong_guangboNew.png'),
          icon_active: require('./images/icons/bangong_guangbo_activeNew.png'),

          scene: 'floor',
          components: 'common',
          category_id: 21,
        },
        {
          key: 'bangong_cunchu',
          name: '网络存储',
          icon: require('./images/icons/bangong_cunchuNew.png'),
          icon_active: require('./images/icons/bangong_cunchun_activeNew.png'),

          scene: 'floor',
          components: 'common',
          category_id: 23,
        },

      ]
    },
    {
      key: 'anquan',
      name: '安全防范',
      icon: require('./images/icons/anquanNew.png'),
      icon_active: require('./images/icons/wangluo_activeNew.png'),

      children: [
        {
          key: 'anquan_jiankong',
          name: '视频监控',
          scene: 'floor',
          components: 'common',
          category_id: 7,
          icon: require('./images/icons/anquan_jiankongNew.png'),
          icon_active: require('./images/icons/anquan_jiankong_activeNew.png'),
        },

        {
          key: 'anquan_ruqing',
          name: '入侵报警',
          icon: require('./images/icons/anquan_ruqingNew.png'),
          icon_active: require('./images/icons/anquan_ruqing_activeNew.png'),
          scene: 'floor',
          components: 'common',
          category_id: 9,
        },
        {
          key: 'anquan_menjing',
          name: '门禁管理',
          icon: require('./images/icons/anquan_menjingNew.png'),
          icon_active: require('./images/icons/anquan_menjing_activeNew.png'),
          scene: 'floor',
          components: 'common',
          category_id: 12,
        },
      ]
    },

    {
      key: 'yunwei',
      name: '运维系统',
      icon: require('./images/icons/yunweiNew.png'),
      icon_active: require('./images/icons/yunwei_active.png')
    },
  ];

  CameraControls.install( { THREE: THREE } );

  const texture = new THREE.TextureLoader().load('/static/model/demo/T_DiBan01.png');
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;

  const clock = new THREE.Clock();

  const fires = [];

  let threeObjs = {
    pointCloud: null,
    referenceCloud: null,
    resultCloud: null,
    mesh: null,
    control: null,
  }

  let materials = {};

  window._threeObjs = threeObjs;

  const ENTIRE_SCENE = 0, BLOOM_SCENE = 1;

  const darkMaterial = new THREE.MeshBasicMaterial( { color: 'black' } );

  const ANIMATION_ROAD_DATA = [
    [
      [
        30.3, 0, 382,
      ],

      [
        30.3, 0, -442,
      ]
    ],


    [
      [
        29.3, 0, 362,
      ],

      [
        29.3, 0, -402,
      ]
    ],

    [
      [
        28.3, 0, 382,
      ],

      [
        28.3, 0, -442,
      ]
    ],

    [
      [
        26.3, 0, -442,
      ],

      [
        26.3, 0, 382,
      ]
    ],

    [
      [
        25.5, 0, -472,
      ],

      [
        25.5, 0, 472,
      ]
    ],

    [
      [
        24.5, 0, -442,
      ],

      [
        24.5, 0, 492,
      ]
    ],



    // 2
    [

      [
        458, 0, -29,
      ],

      [
        -336.3, 0, -29,
      ],
    ],

    [

      [
        458, 0, -27,
      ],

      [
        -336.3, 0, -27,
      ],
    ],

    [

      [
        -442, 0, -23.2,
      ],

      [
        382.3, 0, -23.2,
      ],
    ],

    [

      [
        -442, 0, -25.2,
      ],

      [
        382.3, 0, -25.2,
      ],
    ]
  ];

  const createOpacityWallMat = ({
    height = 10,
    color = "#00ffff",
    opacity = 0.1,
    speed = 1,
  }) => {
    // 顶点着色器
    const vertexShader = `
      uniform vec3 u_color;

      uniform float time;
      uniform float u_height;
      varying float v_opacity;

      void main() {
          vec3 vPosition = position;
          v_opacity = mix(1.0, 0.0, position.y / u_height * 1.0) * (1.0 + sin(time) * 0.5);
          gl_Position = projectionMatrix * modelViewMatrix * vec4(vPosition, 1);
      }
   `;
    // 片元着色器
    const fragmentShader = `
      uniform vec3 u_color;
      uniform float u_opacity;
      varying float v_opacity;
      void main() {
          gl_FragColor = vec4(u_color, v_opacity * u_opacity);
      }
    `;

    return new THREE.ShaderMaterial({
      uniforms: {
        u_height: {
          value: 1,
        },
        u_opacity: {
          value: 0.02,
        },
        u_color: {
          value: new THREE.Color(color),
        },
        time: {
          value: 1,
        },
        speed: {
          value: 1,
        },
      },
      transparent: true,
      depthWrite: false,
      depthTest: true,
      side: THREE.DoubleSide,
      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
    });
  };

  const HOME_SCENE_INFO = ['home'];
  const FLOOR_SCENE_INFO = ['video'];

  const MODEL_PATH =  {
    building: {
      obj: (process.env.NODE_ENV === 'production' ? '' : '') + '/static/model/glb/SM_WaiKe.glb',
    },

    floor: {
      obj: (process.env.NODE_ENV === 'production' ? '' : '') + '/static/model/glb/S_DiXing.glb',
    },

    floors: [
      {
        obj: (process.env.NODE_ENV === 'production' ? '' : '')  + '/static/model/glb/F01.glb',
        mtl: (process.env.NODE_ENV === 'production' ? '' : '')  + '/static/model/glb/F01.mtl',

        rootObj: null,

        children: {

        },

        groups: {
          wall: {
            keys: [
              'SM_QT_F01',
            ],

            material: new THREE.MeshStandardMaterial({
              color: new THREE.Color("rgb(26, 27, 163)"),
              transparent: true,
              opacity: 0.2,
              metalness: 0.4,
              depthWrite: false,
            }),

            // border: true,

            meshes: [],
          },
        }
      },

      {
        obj: (process.env.NODE_ENV === 'production' ? '' : '')  + '/static/model/glb/F02.glb',
        mtl: (process.env.NODE_ENV === 'production' ? '' : '')  + '/static/model/glb/F02.mtl',

        rootObj: null,

        children: {

        },

        groups: {
          wall: {
            keys: [
              'SM_QT_F00',
            ],

            material: new THREE.MeshStandardMaterial({
              color: new THREE.Color("rgb(26, 27, 163)"),
              transparent: true,
              opacity: 0.2,
              metalness: 0.4,
              depthWrite: false,
            }),

            // border: true,

            meshes: [],
          },
        }
      },

      {
        obj: (process.env.NODE_ENV === 'production' ? '' : '')  + '/static/model/glb/F03.glb',
        mtl: (process.env.NODE_ENV === 'production' ? '' : '')  + '/static/model/glb/F03.mtl',

        rootObj: null,

        children: {

        },

        groups: {
          wall: {
            keys: [
              'SM_QT_F00'
            ],

            material: new THREE.MeshStandardMaterial({
              color: new THREE.Color("rgb(26, 27, 163)"),
              transparent: true,
              opacity: 0.2,
              metalness: 0.4,
              depthWrite: false,
            }),


            meshes: [],
          },
        }
      }
    ],

    mesh: {
      floor: {
        lines: {
          keys: ['Sm_LuYa03'],

          material: new THREE.MeshStandardMaterial({
            color: new THREE.Color("rgb(28, 40, 136)"),
          }),

          meshes: [],
          bloom: true,
        },

        car: {
          keys: ['MI_Car08_MXZ'],

          material: new THREE.MeshStandardMaterial({
            color: 0x2a5294,
          }),

          meshes: [],
          bloom: true,
        },

        carLight: {
          keys: ['MI_Car09_MXZ_2', 'MI_Car08_MXZ001_4', 'MI_Car08_MXZ_4'],

          bloom: true,
          meshes: [],

          materialExt: {
            transparent: true,
          }
        },

        road: {
          keys: ['SM_LiuXian00', 'SM_LiuXian03'],

          material: new THREE.MeshStandardMaterial({
            color: new THREE.Color("rgb(42, 72, 60)"),
          }),

          bloom: true,
        },

        road2: {
          keys: ['SM_LiuXian01', 'SM_LiuXian02'],

          material: new THREE.MeshStandardMaterial({
            color: new THREE.Color("rgb(40, 35, 37)"),
          }),
        },

        light: {
          keys: ['SM_HLD01_MXZ002_1', 'SM_HLD01_MXZ002_3'],
          // material: new THREE.MeshStandardMaterial({
          //   color: new THREE.Color("rgb(248, 240, 76)"),
          // }),

          light: true,

          // bloom: true,
        },

        background: {
          keys: ['SM_TiKuai01'],

          meshes: [],
          bloom: false,

          material: new THREE.MeshStandardMaterial({
            color: new THREE.Color("rgb(28, 40, 176)"),
            transparent: true,
            // emissive: new THREE.Color("rgb(26, 27, 163)"),
            opacity: 0.4,
            // metalness: 0.4,
            depthWrite: true,
          }),
        },

        tree: {
          keys: ['SM_Tree_087_1'],

          material: new THREE.MeshStandardMaterial({
            color: 0x4e9000,
            transparent: true,
            // emissive: new THREE.Color("rgb(26, 27, 163)"),
            opacity: 0.3,
          })
        },

        dimian: {
          keys: ['SM_DiMian'],

          material: new THREE.MeshStandardMaterial({
            transparent: true,
            opacity: 0
          }),
        }
      },

      building: {
        lines: {
          keys: [
            'S_JZWK_Xian1'
          ],

          material: new THREE.MeshStandardMaterial({
            color: 0x2a5294,
          }),

          bloom: true,
          meshes: [],
        },

        textLight: {
          keys: [
            'SMI_JZ_WK02_120_el73', 'SMI_JZ_WK02_120_el79', 'SMI_JZ_WK02_120_el42', 'SMI_JZ_WK02_120_el75',
            'SMI_JZ_WK02_120_el78', 'SMI_JZ_WK02_120_el67', 'SMI_JZ_WK02_120_el76', 'SMI_JZ_WK02_120_el97',
            'SMI_JZ_WK02_120_el13', 'SMI_JZ_WK02_120_el95', 'SMI_JZ_WK02_120_el10', 'SMI_JZ_WK02_120_el92',
            'SMI_JZ_WK02_120_el8', 'SMI_JZ_WK02_120_el90', 'SMI_JZ_WK02_120_el3', 'SMI_JZ_WK02_120_el84',
            'SMI_JZ_WK02_120_el27', 'SMI_JZ_WK02_120_el31', 'SMI_JZ_WK02_120_el38', 'SMI_JZ_WK02_120_el40',
            'SMI_JZ_WK02_120_el52', 'SMI_JZ_WK02_120_el51', 'SMI_JZ_WK02_120_el48', 'SMI_JZ_WK02_120_el43',
          ],

          material: new THREE.MeshStandardMaterial({
            color: 0xffffff,
            emissive: 0xffffff,
            transparent: true,
          }),

          // bloom: true,
          meshes: [],
        },


        textAnimate: {
          keys: [
            'SMI_JZ_WK02_120_el22', 'SMI_JZ_WK02_120_el16', 'SMI_JZ_WK02_120_el24',
          ],

          material: new THREE.MeshStandardMaterial({
            color: 0x2a5294,
            transparent: true,
          }),


          materialEnd: new THREE.MeshStandardMaterial({
            color: new THREE.Color("rgb(148, 140, 76)"),
            transparent: true,
          }),

          animate: true,
          bloom: true,
          meshes: [],
        },

        textAnimate2: {
          keys: [

            'SMI_JZ_WK02_120_el66', 'SMI_JZ_WK02_120_el63',
            'SMI_JZ_WK02_120_el58', 'SMI_JZ_WK02_120_el54'
          ],

          materialEnd: new THREE.MeshStandardMaterial({
            color: 0x2a5294,
            transparent: true,
          }),


          material: new THREE.MeshStandardMaterial({
            color: new THREE.Color("rgb(148, 140, 76)"),
            transparent: true,
          }),

          animate: true,
          bloom: true,
          meshes: [],
        },

        lights: {
          keys: [
            'SM_BIM_JZ_Z_1111', 'SM_BIM_JZ_Z_1010', 'SM_BIM_JZ_Z_0909', 'SM_BIM_JZ_Z_0909', 'SM_BIM_JZ_Z_0909', 'SM_BIM_JZ_Z_1212', 'SM_BIM_JZ_Z_1325'
          ],

          bloom: true,
          meshes: [],
        },


        lights2: {
          keys: [
            'SM_BIM_JZ_Z_0707'
          ],

          material: new THREE.MeshStandardMaterial({
            color: new THREE.Color("rgb(148, 140, 76)")
          }),

          bloom: true,
          meshes: [],
        },

        windowLine: {
          keys: [
            'S_JZWK_Xian2'
          ],

          material: new THREE.MeshStandardMaterial({
            color: new THREE.Color("rgb(148, 140, 76)"),
          }),

          bloom: true,
          meshes: [],
        },


        window: {
          keys: [
            'SM_BIM_JZ_M428_N'
          ],

          material: new THREE.MeshStandardMaterial({
            color: new THREE.Color("rgb(148, 140, 76)"),
            transparent: true,
            opacity: 0.5
            // emissive: 0x4c8bf5,
            // roughness: 0,
            // metalness: 1,
            // side: THREE.DoubleSide,
          }),
          bloom: true,
          meshes: [],
        },

        box: {
          keys: [
            'S_JZ_WK00'
          ],

          meshes: [],
          // bloom: true,

          material: new THREE.MeshStandardMaterial({
            color: new THREE.Color("rgb(0, 0, 0)"),
            emissive: new THREE.Color("rgb(26, 35, 43)"),
            opacity: 1
          }),
        },

        text: {
          keys: [
            'S_JZWK_1'
          ],

          material: new THREE.MeshStandardMaterial({
            color: new THREE.Color("rgb(248, 240, 76)"),
            // emissive: 0x4c8bf5,
            // roughness: 0,
            // metalness: 1,
            // side: THREE.DoubleSide,
          }),
          bloom: true,

          meshes: [],
        },

        ground: {
          keys: [
             'S_JZWK_Xian4'
          ],

          material: new THREE.MeshStandardMaterial({
            opacity: 0,
            transparent: true,
          }),

          visible: false,

          meshes: [],
        },

        wuding: {

          keys: [
             'S_JZWK_Xian4_01'
          ],

          bloom: true,
          material: createOpacityWallMat({
            height: 1000,
            color: '#2a5294'
          }),

          meshes: [],
        },

      }
    }
  }

  window.MODEL_PATH = MODEL_PATH;

  let threeInfo = window._threeInfo = {
    scene: null,
    renderer: null,
    camera: null,
    controls: null,
    floorMesh: null,
    composer: null,
  }

  export default {

    computed: {
      ...mapGetters(["floor", "activeLive"]),

      timeStr () {
        return moment(this.nowTimespan).format('MM月DD | HH:mm:ss');
      }
    },

    created () {
      this.$watch('activeLive', () => {
        if (!this.activeLive) {

          this.liveSet.show = false;
          this.liveSet.data = null;

          return;
        }

        this.liveSet.show = true;
        this.liveSet.data = this.activeLive;
      });

      this.$watch('currentModelState', () => {
        if (this.currentModelState == 'floor') {

          MODEL_PATH.building.scene.visible = false;

          if (MODEL_PATH.floors.scene) {
            MODEL_PATH.floors.scene.visible = true;
          } else {
            this.loadFloorModels(() => {
              MODEL_PATH.floors.scene.visible = true;
            });
          }
        } else {
          MODEL_PATH.building.scene.visible = true;
          MODEL_PATH.floors.scene.visible = false;
        }
      });
    },

    mounted () {
      this.createViewer();
    },

    data() {
      return {
        pointers: [],
        icons: ICON_LIST,
        nowTimespan: null,
        currentModelState: 'building',
        currentModelFloor: -1,

        debug: this.$route.query.debug ? true : false,

        sceneSet: {
          current: 'home',
          pointerData: null,
          id: null,
        },

        liveSet: {
          show: false,
          data: null,
        },

        categories: [],

        infoSet: {
          show: false,
          current: 'home',
          current_sub: '',
          components: '',
          currentFloor: -1,
        }
      }
    },

    components: {
      PointerMain,
      InfoCommon,
      InfoHome,
      Live,
    },

    methods: {
      onSwitchFloorModel () {
        this.currentModelState = this.currentModelState == 'building' ? 'floor' : 'building';
      },

      setFloorVisble (activeIdx = -1) {
        this.currentModelFloor = activeIdx;
        MODEL_PATH.floors.forEach((item, idx) => {
          if (!item.rootObj) {
            return;
          }

          if (idx == activeIdx || activeIdx == -1) {
            item.rootObj.visible = true;
          } else {
            item.rootObj.visible = false;
          }
        });
      },

      onPositionUpdate () {
        let data = {
          position: JSON.stringify(this.sceneSet.pointerData)
        }

       $.ajax({
          url: `/api/admin/assets/${this.sceneSet.id}`,
          data: JSON.stringify(data),
          contentType: "application/json",
          dataType:"json",
          loading: false,
          method: 'put',
        }).then(ret => {
          if (ret.code != 'success') {
            return;
          }

          this.$Message.info({
            content: '成功'
          });
        });
      },

      onSummary (summary) {
        this.categories = summary.subCategories.map((sub) => sub.subCategories).flat();
        this.reloaDisabled();
      },

      reloaDisabled () {

        this.icons.forEach((icon) => {
          icon.children && icon.children.forEach((child) => {
            let category = this.categories.filter((_item) => {
              return _item.id == child.category_id;
            })[0];

            this.$set(child, 'canuse', category && category.total > 0 ? true : false);
            this.$set(child, 'category', category);
          })
        });

        // this.onMainIconSub(ICON_LIST[1].children[0]);
      },

      onMainIcon (icon) {
        this.infoSet.current = this.infoSet.current == icon.key ? null : icon.key;
        this.infoSet.show = true;

        if (icon.key == 'yunwei') {
          window.open('/admin');
        }
      },

      onHome (key) {
        this.$refs.pointer_main.clearPoint();


        this.infoSet.current = key;
        this.infoSet.current_sub = '';
        this.infoSet.components = '';
        this.$store.dispatch('activePointer', null);

        this.onScene('home');
      },

      onMainIconSub (child) {
        if (!child.canuse && child.key != 'loukong_zhaoming') {
          this.$Message.info({
            content: '暂无设备'
          });

          return;
        }

        if (child.key != this.infoSet.current_sub) {
          this.$refs.pointer_main.clearPoint();
        }

        this.infoSet.current_sub = child.key;
        this.infoSet.components = child.components;
        this.$store.dispatch('activePointer', null);

        if (child.scene == 'floor') {
          this.onScene('floor', 0, child);
          // this.infoSet.current = null;
        }

        if (child.scene == 'home') {
          this.onScene('home');
        }
      },

      onPointerReady (dom) {
        threeInfo.$dom = dom;

        this.$dom = dom;
      },

      onAddPoints (points) {
        this.$refs.pointer_main.clearPoint();

        points.forEach((point) => {
          this.$refs.pointer_main.addPointer(point);
        });
      },

      initHelper () {
        let axisHelper = new THREE.AxesHelper(100);
        threeInfo.scene.add(axisHelper);
      },

      createViewer () {
        threeInfo.renderer = new THREE.WebGLRenderer( {
          antialias: true,
          alpha: true,
        });

        // threeInfo.renderer.autoClear = false;
        threeInfo.renderer.setPixelRatio( window.devicePixelRatio);
        threeInfo.renderer.setSize(this.$refs.viewer.offsetWidth, this.$refs.viewer.offsetHeight);
        threeInfo.renderer.setAnimationLoop(() => {
          this.animate();
        });
        threeInfo.renderer.outputEncoding = THREE.sRGBEncoding;
        // threeInfo.renderer.toneMapping = THREE.ACESFilmicToneMapping;

        // 摄像机
        threeInfo.camera = new THREE.PerspectiveCamera(60, this.$refs.viewer.offsetWidth / this.$refs.viewer.offsetHeight, 1, 10000);
        threeInfo.camera.position.set( 0, 0, 5 );
        threeInfo.cameraControls = new CameraControls(threeInfo.camera, this.$dom);
        threeInfo.cameraControls.noZoom = true;
        threeInfo.cameraControls.maxDistance = 100;

        threeInfo.scene = new THREE.Scene();

        // 全景贴图
        new RGBELoader()
          .setPath((process.env.NODE_ENV === 'production' ? '' : '') +  '/static/model/demo/maps/' )
          .load('space_03.hdr', function ( texture ) {
            texture.mapping = THREE.EquirectangularReflectionMapping;
            threeInfo.scene.background = texture;
            // threeInfo.scene.environment = texture;
          });

        // 缩放窗口
        window.addEventListener('resize', () => {
          this.onResize();
        });

        this.$refs.viewer.appendChild(threeInfo.renderer.domElement);

        this.loadModels();
        this.initLights();
        this.initFloor();
        this.initEffect();

        this.initAnimations();
        this.initScenes();
        this.initTools();

        this.animateIn();

        // 2d渲染配置
        this.$refs.pointer_main.setThreeInfo(threeInfo);
      },

      initScenes () {
        this.sceneFloor = new SceneFloor(threeInfo, threeInfo.$dom, this.debug);

        this.sceneFloor.addEventListener('pointer',  (e) => {
          this.sceneSet.pointerData = {
            position: e.data.position,
            camera: e.data.camera,
            object_name: e.data.object_name
          }
        });

        this.sceneFloor.addEventListener('floor',  (e) => {
          this.infoSet.currentFloor = e.data.floor;
          this.$store.dispatch('set_floor', e.data.floor);
        });
      },


      onFloor (floor) {
        this.sceneFloor.setFloor(floor, false);
        this.$store.dispatch('set_floor', floor);
      },

      onScene (key, idx, category) {
        this.sceneSet.current = key;

        if (key == 'floor') {
          this.sceneFloor.setFloor(idx, true, category);
          this.$refs.pointer_main.setThreeInfo(this.sceneFloor.floorThreeInfo);

          if (this.debug) {
            // 切换point跟踪
            this.measurePoint.close();
            this.sceneFloor.measurePoint.open();
          }
        }

        if (key == 'home') {
          this.animateIn();
          this.$refs.pointer_main.setThreeInfo(threeInfo);

          // 切换point跟踪
          if (this.debug) {
            this.measurePoint.open();
            this.sceneFloor.measurePoint.close();
          }
        }
      },

      initTools () {
        if (this.debug) {
          this.measurePoint = new MessurePoint(threeInfo);
          this.measurePoint.open();

          this.measurePoint.addEventListener('point', (event) => {
            this.clearPoint();

            this.addPoint(event.position, event.object);
          });
        }
      },

      clearPoint () {
        this.pointers.forEach((pointer) => {
          threeInfo.scene.remove(pointer.mesh);
        });

        this.pointers = [];
      },

      addPoint (position, object) {
        const map = new THREE.TextureLoader().load( '/static/model/demo/maps/disc.png' );

        const material = new THREE.SpriteMaterial( {
          map: map,
          color: 0xffff00,
          fog: true,
          sizeAttenuation: false,
          depthTest: false,
        });

        let sphereInter = new THREE.Sprite(material );
        sphereInter.scale.set(0.03, 0.03, 1);
        sphereInter.position.copy(position);

        let pointer = {
          mesh: sphereInter,
          position: sphereInter.position,
          camera: {
            position: threeInfo.cameraControls.getPosition(),
            target: threeInfo.cameraControls.getTarget(),
          }
        };

        this.pointers.push(pointer);

        threeInfo.scene.add(sphereInter);

        this.sceneSet.pointerData = {
          position: pointer.position,
          camera: pointer.camera,
          object_name: object ? object.name : ''
        }
      },

      animateIn () {
        threeInfo.cameraControls.setLookAt(5, 5, 5, 0, 0, 0);

        setTimeout(() => {
          threeInfo.cameraControls.setLookAt(17.602316030927646, 25.85326980218973, -47.67000809498836,
            -7.389124471804775, -0.5940642923566619, -3.6837989, true);
        }, 100);
      },

      initAnimations () {
        this.animationRoad = new AnimationRoad(
          threeInfo, ANIMATION_ROAD_DATA
        );

        this.animationRoad.point_effect();
      },

      initEffect () {
        let target = new THREE.WebGLRenderTarget(this.$refs.viewer.offsetWidth, this.$refs.viewer.offsetHeight);

        threeInfo.bloomLayer = new THREE.Layers();
        threeInfo.bloomLayer.set( BLOOM_SCENE );

        const params = {
          exposure: 0.95,
          bloomStrength: 1,
          bloomRadius: 0.2
        };

        threeInfo.composer = new EffectComposer( threeInfo.renderer);
        threeInfo.composer.renderToScreen = false;

        // 渲染场景
        const renderPass = new RenderPass(threeInfo.scene, threeInfo.camera);
        threeInfo.composer.addPass( renderPass );


        // 设置光晕效果
        const bloomPass = new UnrealBloomPass( new THREE.Vector2( this.$refs.viewer.offsetWidth,this.$refs.viewer.offsetHeight ),
          1, 0, 0
        );
        bloomPass.threshold = params.bloomThreshold;
        bloomPass.strength = params.bloomStrength;
        bloomPass.radius = params.bloomRadius;
        threeInfo.renderer.toneMappingExposure =  Math.pow(params.exposure, 4.0 );
        threeInfo.composer.addPass( bloomPass );

        // 光晕复制效果
        const finalComposer = new EffectComposer( threeInfo.renderer);

        // debugger;
        // finalComposer.renderTarget1.format = finalComposer.renderTarget2.format = ;

        const finalPass = new ShaderPass(
          new THREE.ShaderMaterial( {
            uniforms: {
              baseTexture: { value: null },
              bloomTexture: { value: threeInfo.composer.renderTarget2.texture }
            },
            vertexShader: document.getElementById( 'vertexshader' ).textContent,
            fragmentShader: document.getElementById( 'fragmentshader' ).textContent,
            defines: {}
          } ), 'baseTexture'
        );
        finalPass.needsSwap = true;

        // 设置抗锯齿数据 ffx
        const fxaaPass = new ShaderPass( FXAAShader );
        fxaaPass.uniforms[ 'resolution' ].value.x = 1 / ( this.$refs.viewer.offsetWidth  );
        fxaaPass.uniforms[ 'resolution' ].value.y = 1 / ( this.$refs.viewer.offsetHeight  );

        // 设置抗锯齿数据 ffx

        const ssaaRenderPass = new SSAARenderPass( threeInfo.scene, threeInfo.camera);
        // ssaaRenderPass.unbiased = false;
        ssaaRenderPass.sampleLevel = 4;

        // 像素效果
        const pixelPass = new ShaderPass( PixelShader );
        pixelPass.uniforms[ 'resolution' ].value = new THREE.Vector2( window.innerWidth, window.innerHeight );
        pixelPass.uniforms[ 'resolution' ].value.multiplyScalar( window.devicePixelRatio );
        pixelPass.uniforms[ 'pixelSize' ].value = 5;
        pixelPass.clear = true;

        finalComposer.addPass( renderPass );
        finalComposer.addPass( finalPass );
        finalComposer.addPass( fxaaPass );
        threeInfo.finalComposer = finalComposer;
      },

      onResize () {
        threeInfo.renderer.setSize(this.$refs.viewer.offsetWidth, this.$refs.viewer.offsetHeight);

        threeInfo.camera.aspect = this.$refs.viewer.offsetWidth / this.$refs.viewer.offsetHeight;
        threeInfo.camera.updateProjectionMatrix();
      },

      lightEffect () {
        const sprite = new THREE.TextureLoader().load('/static/model/building/maps/lensflare1.png');

        const material = new THREE.PointsMaterial( {
          size: 5,
          sizeAttenuation: true,
          map: sprite,
          alphaMap: sprite,
          // alpha: 0.5,

          transparent: true,
          alphaToCoverage: true,
        });


        let geometry = new THREE.BufferGeometry();

        const vertices = [
          -85.64205185985907,
          7.357959102212703,
          -20.66427138453285 ,

          22.13736899968241,
          7.292991761515498,
          35.92216600719661 ,


          -58.09215212673555,
          7.2338843910255015,
          -20.60216825831558,

          22.219999038825193,
          7.227011858448545,
          6.753368418644589,

          22.178313555105742,
          7.21185595778166,
          21.281843768698607,

          -35.50727654607516,
          7.23414270177959,
          -20.602816842555228,


          22.095532686575922,
          7.278440676013621,
          50.41412205203529,


          22.264975212302186,
          7.334037616836082,
          64.41412205203529
        ];

        geometry.setAttribute( 'position', new THREE.Float32BufferAttribute( vertices, 3 ) );

        const particles = new THREE.Points(geometry, material);
        // particles.layers.enable(0);

        threeInfo.scene.add(particles);
      },

      createFire () {
        [
          [5, 0, 20, 35], [15, -5, 40, 35], [-30, 5, 20, 35], [-15, 2, 30, 35]
        ].forEach((pointFire) => {

          const map = new THREE.TextureLoader().load( '/static/model/building/maps/1.png' );

          map.center =  new THREE.Vector2(0.5, 0.5);
          map.wrapS = THREE.RepeatWrapping;
          map.wrapT = THREE.RepeatWrapping;
          map.repeat.set(1, 1);
          map.rotation = Math.PI / 2;

          const materialLine = new MeshLineMaterial({
            // color: new THREE.Color("rgb(70, 70, 70)"),
            // opacity: .4,
            lineWidth : 3,
            map: map,
            useMap: 1,
            transparent: true,
            // alphaToCoverage: true,
            // depthWrite: true,

            alphaMap: map,
            depthWrite: false,
            // useAlphaMap: 1,

            // alphaTest: 0.1,
          });

          const geo = new THREE.BufferGeometry();
          const positions = [
            ...pointFire.slice(0, 3),

            pointFire[0],
            pointFire[1] + 16,
            pointFire[2]
          ];

          geo.setAttribute('position', new THREE.BufferAttribute(new Float32Array(positions), 3 ));

          let line = new MeshLine();
          line.setGeometry(geo);

          let lineMesh = new THREE.Mesh(line, materialLine);

          // lineMesh.layers.enable(1);

          threeInfo.scene.add( lineMesh );


          let tweenLine = new TWEEN.Tween( lineMesh.position ).to(
            {
              y: Math.random() * 80 + 60,
            }, Math.random() * 1000 + 2500
          ).repeat(Infinity).delay(500);

          tweenLine.start();

          return;

          const teapotGeometry = new THREE.BoxGeometry (1, 5, 1);
          const sphereGeometry = new THREE.SphereGeometry( 2, 2, 1.6 );

          const geometry = new THREE.BufferGeometry();

          // buffers
          const speed = [];
          const intensity = [];
          const size = [];

          const positionAttribute = teapotGeometry.getAttribute( 'position' );
          const particleCount = positionAttribute.count;

          for ( let i = 0; i < particleCount; i ++ ) {

            speed.push( 20 + Math.random() * 50 );

            intensity.push( Math.random() * .15 );

            size.push( 2 + Math.random() * pointFire[3] );

          }

          geometry.setAttribute( 'position', positionAttribute );
          geometry.setAttribute( 'targetPosition', sphereGeometry.getAttribute( 'position' ) );
          geometry.setAttribute( 'particleSpeed', new THREE.Float32BufferAttribute( speed, 1 ) );
          geometry.setAttribute( 'particleIntensity', new THREE.Float32BufferAttribute( intensity, 1 ) );
          geometry.setAttribute( 'particleSize', new THREE.Float32BufferAttribute( size, 1 ) );

          // maps
          const fireMap = new THREE.TextureLoader().load( '/static/model/building/maps/firetorch_1.jpg' );

          // nodes

          const targetPosition = new Nodes.AttributeNode( 'targetPosition', 'vec3' );
          const particleSpeed = new Nodes.AttributeNode( 'particleSpeed', 'float' );
          const particleIntensity = new Nodes.AttributeNode( 'particleIntensity', 'float' );
          const particleSize = new Nodes.AttributeNode( 'particleSize', 'float' );

          const time = new Nodes.TimerNode();

          const spriteSheetCount = new Nodes.ConstNode( new THREE.Vector2( 6, 6 ) );

          const fireUV = new Nodes.SpriteSheetUVNode(
            spriteSheetCount, // count
            new Nodes.PointUVNode(), // uv
            new Nodes.OperatorNode( '*', time, particleSpeed ) // current frame
          );

          const fireSprite = new Nodes.TextureNode( fireMap, fireUV );
          const fire = new Nodes.OperatorNode( '*', fireSprite, particleIntensity );

          const lerpPosition = new Nodes.UniformNode( 0 );

          const positionNode = new Nodes.MathNode( Nodes.MathNode.MIX, new Nodes.PositionNode( Nodes.PositionNode.LOCAL ), targetPosition, lerpPosition );

          // material

          const material = new Nodes.PointsNodeMaterial( {
            depthWrite: false,
            transparent: true,
            sizeAttenuation: true,
            blending: THREE.AdditiveBlending
          } );

          material.colorNode = fire;
          material.sizeNode = particleSize;
          material.positionNode = positionNode;

          const particles = new THREE.Points( geometry, material );

          particles.renderOrder = 1;

          particles.position.set(pointFire[0], pointFire[1], pointFire[2]);
          threeInfo.scene.add( particles );

          let tween = new TWEEN.Tween( particles.position ).to(
            {
              y: Math.random() * 80 + 60,
            }, Math.random() * 1000 + 1500
          ).repeat(Infinity).delay(500);

          tween.start();
        });

        // return;
//
        // const fireTex = new THREE.TextureLoader().load( '/static/model/building/maps/light_blue.png' );

        // var plane = new THREE.PlaneBufferGeometry( 20, 20 );


        // var fire = new Fire(fireTex);
        // fire.position.set(20, 20, 20);

        // threeInfo.scene.add(fire);

        // var wireframeMat = new THREE.MeshBasicMaterial({
        //   color : new THREE.Color(0xffffff),
        //   wireframe : true
        // });

        // var wireframe = new THREE.Mesh(fire.geometry, wireframeMat.clone());
        // fire.add(wireframe);
        // wireframe.visible = false;

        // fires.push(fire);
      },

      loadFloorModels (success) {
        let floorScene = new THREE.Object3D();
        floorScene.visible = false;

        MODEL_PATH.floors.forEach((floor, idx) => {
          let loader = new GLTFLoader();

          loader.load(floor.obj, (ret) => {
            this.anaylseGeomerty(ret.scene, floor.groups);
            this.setContent(ret.scene);

            floor.rootObj = ret.scene;
            this.measurePoint && this.measurePoint.addTest(ret.scene);

            floorScene.add(ret.scene);

            if (idx == MODEL_PATH.floors.length - 1) {
              success && success();
            }
          }, (xhr) => {

          });
        });

        MODEL_PATH.floors.scene = floorScene;

        threeInfo.scene.add(floorScene);
      },

      loadModels () {
        let loader = new GLTFLoader();

        this.loading = 1;

        loader.load(MODEL_PATH.building.obj, (ret) => {
          this.anaylseGeomerty(ret.scene, MODEL_PATH.mesh.building);

          ret.scene.position.set(1, 0.5, 0.5);
          ret.scene.scale.set(1.2, 1.2, 1.2);

          MODEL_PATH.building.scene = ret.scene;
          this.setContent(ret.scene);
          this.loading = 0;
          this.measurePoint && this.measurePoint.addTest(ret.scene);
          this.createFire();
          this.lightEffect();

          // this.createEffects();

          // debugger
          // this.onMainIconSub(ICON_LIST[3].children[1]);
        }, (xhr) => {
          this.loading = 1;
        });

        // let loader = new OBJLoader();
        // let material;

        // let mlt = new MTLLoader().load(MODEL_PATH.building.mtl, (ret) => {
        //   material = ret;
        //   material.preload();
        //   loadObj();
        // }, null, () => {

        // });


        // let loadObj = () => {

        // }
      },

      anaylseGeomerty (obj, geometryInfos) {
        let children = obj.children.map((item) => {
          return item.children.length ? item.children : item;
        });

        children = children.flat();

        Object.keys(geometryInfos).forEach((key) => {
          let infoItem = geometryInfos[key];

          let meshes = children.filter((item) => {

            return infoItem.keys.indexOf(item.name) > -1;
          });

          infoItem.meshes = meshes;

          if (infoItem.material) {
            meshes.forEach((mesh) => {
              mesh.material = infoItem.material;
            });
          }

          if (infoItem.materialExt) {
            meshes.forEach((mesh) => {

              Object.keys(infoItem.materialExt).forEach(function (name) {

                mesh.material[name] = infoItem.materialExt[name];
              });

            });

          }

          if (infoItem.bloom) {
            meshes.forEach((mesh) => {
              mesh.layers.enable(BLOOM_SCENE);
            });
          }

          if (infoItem.visible === false) {
            meshes.forEach((mesh) => {
              mesh.visible = false;
            });
          }

          if (infoItem.animate) {
            meshes.forEach((mesh) => {
              // debugger;
              let tween = new TWEEN.Tween( mesh.material.color ).to(
                {
                  r: infoItem.materialEnd.color.r,
                  g: infoItem.materialEnd.color.g,
                  b: infoItem.materialEnd.color.b,
                }, 500
              ).delay(1000);


              let tweenBack = new TWEEN.Tween( mesh.material.color ).to(
                {
                  r: infoItem.material.color.r,
                  g: infoItem.material.color.g,
                  b: infoItem.material.color.b,
                }, 500
              ).delay(1000);

              tween.chain(tweenBack);
              tweenBack.chain(tween);


              tween.start();
            });
          }
        });
      },

      createEffects () {

        const edges = new THREE.EdgesGeometry(MODEL_PATH.mesh.building.windowLine.meshes[0].geometry);
        const line = new THREE.LineSegments( edges, new THREE.LineBasicMaterial( {
          color: 0x263f61,
          lineWidth: 1
        }));

        threeInfo.scene.add(line);
      },

      initFloor () {
        let loader = new GLTFLoader();

        loader.load(MODEL_PATH.floor.obj, (ret) => {

          ret.scene.position.set(0, .2, 0);

          this.anaylseGeomerty(ret.scene, MODEL_PATH.mesh.floor);
          this.setContent(ret.scene);
          this.measurePoint && this.measurePoint.addTest(ret.scene);
        }, (xhr) => {

        });


        // 反射材质
        const floorGeometry = new THREE.PlaneGeometry(2000, 2000);
        const floorMesh = new THREE.Mesh(floorGeometry);

        floorMesh.material = new MeshReflectorMaterial(threeInfo.renderer, threeInfo.camera, threeInfo.scene, floorMesh, {
          mirror: 1,
        });

        // floorMesh.material.transparent = true;
        floorMesh.material.opacity = 0.8;

        threeInfo.floorMesh = floorMesh;

        floorMesh.rotateX(-Math.PI / 2);
        floorMesh.position.set(500, 0.2, 500);

        // threeInfo.scene.add(floorMesh);


        // 科技圈
        const quanGeo = new THREE.PlaneGeometry(500, 500);
        const quanMesh = new THREE.Mesh(quanGeo);

        quanMesh.position.set(0, 0.1, 0);

        const map = new THREE.TextureLoader().load( '/static/model/building/maps/quan.png' );


        quanMesh.material = new THREE.MeshStandardMaterial( {
          map: map,
          useMap: 1,

          // alphaMap: map,
          // useAlphaMap: 1,
          depthWrite: true,

          alphaTest: 0.01,
          transparent: true,


          // color: new THREE.Color("rgb(148, 140, 76)")
        });

        quanMesh.rotateX(-Math.PI / 2);

        let tween = new TWEEN.Tween( quanMesh.rotation ).to(
          {
            z: Math.PI,
          }, 6000
        ).delay(1000);


        let tweenBack = new TWEEN.Tween( quanMesh.rotation ).to(
          {
            z: 0,
          }, 6000
        ).delay(1000);

        tween.chain(tweenBack);
        tweenBack.chain(tween);
        tween.start();

        threeInfo.scene.add(quanMesh);
      },

      initLights () {
        // 环境光
        let hemiLight = new THREE.HemisphereLight( 0xddeeff, 0x0f0e0d, 1.3 );
        threeInfo.scene.add( hemiLight );


        let hemiLight1 = new THREE.HemisphereLight( 0xddeeff, 0x0f0e0d, 1 );
        threeInfo.scene.add( hemiLight1 );
        hemiLight1.layers.set(BLOOM_SCENE);



        // const ambientLight = new THREE.AmbientLight(0xffffff, 1);    //环境光
        // threeInfo.scene.add( ambientLight );

        // sence.add(ambientLight)

        // 定向光源
        // const color = 0xffffff;
        // const intensity = 1;
        // const light = new THREE.DirectionalLight(color, intensity);
        // light.position.set(100, 100, 100);
        // light.castShadow = true;
        // threeInfo.scene.add(light);


        // const ambientLight = new THREE.AmbientLight( 0x000000 );
        // threeInfo.scene.add( ambientLight );

        // const light1 = new THREE.PointLight( 0xffffff, 1, 0 );
        // light1.position.set( 0, 200, 0 );
        // threeInfo.scene.add( light1 );

        // const light2 = new THREE.PointLight( 0xffffff, 1, 0 );
        // light2.position.set( 100, 200, 100 );
        // threeInfo.scene.add( light2 );

        // const light3 = new THREE.PointLight( 0xffffff, 1, 0 );
        // light3.position.set( - 100, - 200, - 100 );
        // threeInfo.scene.add( light3 );

        // 光源辅助线
        // const helper = new THREE.DirectionalLightHelper(light)
        // threeInfo.scene.add(helper)
      },

      setContent (object, preventRatation) {
        threeInfo.scene.add(object);

        // let helper = new THREE.BoxHelper(object, 0xff0000);
        // threeInfo.scene.add(helper);
      },

      darkenNonBloomed( obj ) {
        if ((obj.isMesh || obj.isPoints || obj.lensflare) && threeInfo.bloomLayer.test( obj.layers ) === false ) {

          if (obj.lensflare) {
            obj.visible = false;
            console.log('light hide');
          } else {
            materials[ obj.uuid ] = obj.material;
            obj.material = darkMaterial;
          }

        }
      },

      restoreMaterial( obj ) {
        if (obj.lensflare) {
          obj.visible = true;
        }

        if ( materials[ obj.uuid ] ) {
          obj.material = materials[ obj.uuid ];
          delete materials[ obj.uuid ];
        }
      },

      animate () {

        let last = this.nowTimespan;

        this.nowTimespan = (+new Date() - this.nowTimespan) > 40 ? +new Date() : this.nowTimespan;

        // 渲染2d信息，2d信息不分场景
        this.$refs.pointer_main && this.$refs.pointer_main.render();

        if (this.sceneFloor && this.sceneSet.current == 'floor') {
          this.sceneFloor.render();
          return;
        }

        // 更新控制器相机
        const delta = clock.getDelta();
        threeInfo.cameraControls.update( delta );

        // threeInfo.renderer.render(threeInfo.scene, threeInfo.camera);

        // 渲染光影特效层
        threeInfo.scene.traverse(this.darkenNonBloomed);
        threeInfo.composer.render();
        threeInfo.scene.traverse(this.restoreMaterial);

        if (last != this.nowTimespan) {
          threeInfo.floorMesh.material.update();
        }

        // // 渲染模型层
        threeInfo.finalComposer.render();

        TWEEN.update();

        // 其他的animate
        this.animationRoad.animate();

        // 更新测量
        this.measurePoint && this.measurePoint.render();

        // 更新火焰
        nodeFrame.update();
      },
    }
  }
</script>

<style lang="less">
  @import '~@/assets/mixin.less';

  .model-base-layout {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
    background: #000;
    position: relative;


    .model-base-title {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: 112px;
      background: #fff;
      z-index: 99;
      background: url('./images/home_title.png') no-repeat center center;
      background-size: 100% 100%;
      text-align: center;

      .base-time {
        position: absolute;
        right: 15px;
        top: 25px;
        color: #f7f7f7;
        width: 200px;
        text-align: right;
      }

      .base-name {
        font-weight: bold;
        color: #E0E3EF;
        font-size: 32px;
        margin-top: 10px;

        .base-action-floor {
          width: 470px;
          height: 96px;
          line-height: 45px;
          background: url('./images/base_action.png') no-repeat center center;
          margin: 0 auto;
          margin-top: 10px;
          text-align: center;
          font-size: 14px;
          font-weight: 500;
          opacity: 1;
          color: #C8D4FF;
          cursor: pointer;
        }

        .base-name-tip {
          font-weight: normal;
          font-size: 13px;
        }
      }

      .base-logo {
        width: 237px;
        height: 36px;
        position: absolute;
        left: 10px;
        top: 20px;

        img {
          display: block;
          //width: 100%;
          height: 100%;
          margin-left: 15px;
        }
      }
    }

    .model-base-bottom {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 137px;
      background: #fff;
      z-index: 99;
      background: url('./images/home_bottom.png') no-repeat center center;
      background-size: 100% 100%;
      text-align: center;

      .bottom-icon-wrap {
        width: 1000px;
        margin: 0 auto;
        height: 137px;
        position: relative;
        font-size: 13px;

        .icon-item {
          position: absolute;
          text-align: center;
          opacity: 1;
          cursor: pointer;
          transition: opacity 0.5s;
          top: 35px;

          &:hover {
            opacity: 1;
          }

          &.icon-item-active {
            opacity: 1;
            cursor: default;
          }

          &.icon-item-home {
            left: 100px;
          }

          &.icon-item-1 {
            left: 90px;
          }

          &.icon-item-wangluo {
            left: 250px;
            .icon-children{
              left: 640px;
            }
          }

          &.icon-item-loukong {
            left: 400px;
            .icon-children{
              left: 738px;
            }
          }

          &.icon-item-bangong {
            left: 550px;
            .icon-children{
              left: 880px;
            }
          }
          &.icon-item-anquan {
            left: 700px;
            .icon-children{
              left: 1070px;
            }
          }
          &.icon-item-yunwei {
            left: 850px;
          }

          .icon-image {
            display: block;
            width: 75px;
            height: 65px;

            img {
              display: block;
              width: 100%;
              height: 100%;
            }
          }

          .icon-title {
            font-size: 12px;
            color: #fff;
            height: 20px;
            line-height: 24px;
          }

          .icon-children {
            position: fixed;
            display: flex;
            //flex-wrap: wrap;
            left: 620px;
            //top: 260px;

            bottom: 100px;
            width: 220px;
            align-items: flex-start;
            justify-content: space-between;

            .icon-children-item {
              display: block;
              cursor: not-allowed;
              margin-bottom: 20px;
              margin-right: 10px;
              width: 105px;
              //overflow-x: hidden;

              &:nth-child(2n+1) {
                //margin-top: -60px;
              }

              &:hover {
                img {
                  opacity: 1;
                }
              }

              &.icon-children-item-canuse {
                cursor: pointer;
              }

              &.icon-children-item-active {
                opacity: 1;

                img {
                  opacity: 1;
                }
              }

              .icon-child-title {
                font-size: 12px;
                color: #fff;
                margin-bottom: 5px;
                .one-line();
              }

              img {
                display: block;
                width: 75px;
                height: 65px;
                margin: 0 auto;
              }
            }
          }
        }
      }
    }


    .model-base-right {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 96px;
      height: 474px;
      z-index: 99;
      background: url('./images/home_right.png') no-repeat center center;
      background-size: 100% 100%;

      .right-icon-wrap {
        .icon-item {
          cursor: pointer;
          width: 42px;
          height: 42px;
          background: url('./images/icons/floor.png') no-repeat center center;
          background-size: cover;
          text-align: center;
          line-height: 42px;
          font-size: 15px;
          color: #fff;
          opacity: 0.5;
          transition: opacity 0.5s;
          position: absolute;

          &.icon-item-all {
            position: absolute;
            top: 140px;
            right: 15px;
          }

          &.icon-item-1f  {
            position: absolute;
            top: 290px;
            right: 15px;
          }


          &.icon-item-2f  {
            position: absolute;
            top: 240px;
            right: 23px;
          }

          &.icon-item-3f  {
            position: absolute;
            top: 190px;
            right: 23px;
          }

          &.icon-item-active {
            opacity: 1;
          }

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    .model-content {
      flex: 1 1 auto;
      height: 0;
      background: #000;
      color: #fff;
      position: relative;
    }

    .pointer-data {
      position: absolute;
      right: 50px;
      top: 100px;
      text-align: center;
      background: #fff;
      padding: 10px;
      z-index: 9999;
      opacity: 0.7;
    }

    .model-pointer-wrap {
      position: fixed;
      left: 100px;
      top: 100px;
      z-index: 99999;
      background: #fff;
    }

    .model-info-set {
      position: absolute;
      left: 20px;
      top: 80px;
      width: 324px;
      z-index: 99;

      .info-block-main {
        margin-bottom: 10px;

        .info-block-content {
          margin: 0;
          border: 1px solid #2B3765;
          background: linear-gradient(180deg, rgba(10, 15, 27, 0.6) 0%, rgba(12, 19, 38, 0.6) 100%);
          opacity: 1;
          border-radius: 20px;
          padding: 5px 10px;

          &.info-block-content-less {
            border: none;
            background: none;
            padding: 0 10px;
          }
        }

        &.info-block-main-notitle {
          .info-block-detail {
            margin: 0 10px;
            border: 1px solid #2B3765;
            background: linear-gradient(180deg, rgba(10, 15, 27, 0.86) 0%, rgba(12, 19, 38, 0.86) 100%);
            opacity: 1;
            border-radius: 20px;
            padding: 10px;
          }
        }

        // &:before {
        //   content: "";
        //   display: block;
        //   width: 324px;
        //   height: 27px;
        //   background: url('./images/info-block-top.png') no-repeat center center;
        //   background-size: 100% 100%;
        // }

        // &:after {
        //   display: block;
        //   content: "";
        //   width: 324px;
        //   height: 27px;
        //   background: url('./images/info-block-bottom.png') no-repeat center center;
        //   background-size: 100% 100%;
        // }
      }

      .info-title {
        color: #fff;
        margin-bottom: 10px;
        font-size: 16px;
        padding-top: 5px;
        padding-left: 5px;

        span {
          font-size: 12px;
        }


        background: url('./images/content_title.png') no-repeat right center;
        background-size: 150px 12px;
      }
    }

    .scene-action-wrap {
      position: absolute;
      right: 50px;
      bottom: 30px;
      text-align: center;
      z-index: 100;
    }
  }
</style>
