import * as THREE from 'three';
import { MeshLine, MeshLineMaterial, MeshLineRaycast } from 'three.meshline';

class StarAnimation {
  constructor (threeInfo, linesData) {
    this.threeInfo = threeInfo;
    this.centerObj = null;
  }

  animate () {
    if (!this.centerObj) {
      return;
    }

    this.centerObj.rotation.y += 0.0001;
  }

  point_effect () {
    const vertices = [];

    const sprite = new THREE.TextureLoader().load( '/static/model/demo/maps/disc.png' );

    const geometry = new THREE.SphereGeometry(1, 32, 32);
    const material = new THREE.MeshBasicMaterial( { color: 0xffffff } );

    let parameters = [
      [[ 1.0, 0.2, 0.5 ], null, 3 ],
      [[ 0.95, 0.1, 0.5 ], null, 1.5 ],
      [[ 0.90, 0.05, 0.5 ], null, 1 ],
      [[ 0.85, 0, 0.5 ], null, .8 ],
      [[ 0.80, 0, 0.5 ], null, .5 ]
    ];

    let centerObj = new THREE.Object3D();


    for ( let j = 0; j < parameters.length; j ++ ) {

      for ( let i = 0; i < 100; i ++ ) {

        const x = Math.random() * 2000 - 1000;
        const y = Math.random() * 2000 -1000;
        const z = Math.random() * 2000 - 1000;

        geometry.radius = parameters[j][2];

        const sphere = new THREE.Mesh(geometry, material);

        // debugger;

        sphere.translateX(x);
        sphere.translateY(y);
        sphere.translateZ(z);

        sphere.rotation.x = Math.random() * 6;
        sphere.rotation.y = Math.random() * 6;
        sphere.rotation.z = Math.random() * 6;

        centerObj.add(sphere);

        // this.spheres.push(sphere);
      }
    }

    this.centerObj = centerObj;
    this.threeInfo.scene.add( centerObj );
  }
}

export default StarAnimation;