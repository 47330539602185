var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"page"}},[_c('div',{staticClass:"model-base-layout"},[(_vm.liveSet.data)?_c('div',[_c('live',{ref:"live",attrs:{"liveData":_vm.liveSet.data},model:{value:(_vm.liveSet.show),callback:function ($$v) {_vm.$set(_vm.liveSet, "show", $$v)},expression:"liveSet.show"}})],1):_vm._e(),_c('div',{staticClass:"model-base-title"},[_c('div',{staticClass:"base-logo"},[_c('img',{attrs:{"src":require("./images/home_logoNew.png")}})]),_c('div',{staticClass:"base-name"},[_vm._v(" 智慧园区数字孪生物联管控平台 "),_c('div',{staticClass:"base-name-tip"},[_vm._v(" SMART PARK IOT CONTROL PLATFORM ")]),(_vm.sceneSet.current == 'home')?_c('div',{staticClass:"base-action-floor",on:{"click":_vm.onSwitchFloorModel}},[_vm._v(" "+_vm._s(_vm.currentModelState == 'building' ? '楼层导航' : '模型全景')+" ")]):_vm._e()]),_c('div',{staticClass:"base-time"},[_vm._v(" "+_vm._s(_vm.timeStr)+" ")])]),_c('div',{staticClass:"model-base-bottom"},[_c('div',{staticClass:"bottom-icon-wrap"},_vm._l((_vm.icons),function(icon,idx){return _c('div',{class:`icon-item ${_vm.infoSet.current == icon.key ? 'icon-item-active' : ''} icon-item-${icon.key}`,on:{"click":function($event){icon.isHome ? _vm.onHome('home') : _vm.onMainIcon(icon)}}},[_c('div',{staticClass:"icon-image"},[(_vm.infoSet.current == icon.key)?_c('img',{attrs:{"src":icon.icon_active}}):_c('img',{attrs:{"src":icon.icon}})]),_c('div',{staticClass:"icon-title"},[_vm._v(_vm._s(icon.name))]),(icon.children && icon.children.length && _vm.infoSet.current == icon.key)?_c('div',{staticClass:"icon-children"},_vm._l((icon.children),function(child){return _c('div',{class:{
                'icon-children-item': true,
                'icon-children-item-canuse': child.canuse,
                'icon-children-item-active': child.key == _vm.infoSet.current_sub,
              },on:{"click":function($event){$event.stopPropagation();return _vm.onMainIconSub(child)}}},[_c('img',{attrs:{"src":child.key == _vm.infoSet.current_sub ? child.icon_active : child.icon}}),_c('div',{staticClass:"icon-child-title"},[_vm._v(_vm._s(child.name))])])}),0):_vm._e()])}),0)]),(_vm.sceneSet.current == 'floor'&&(_vm.currentModelState=='building'||_vm.currentModelState=='floor'))?_c('div',{staticClass:"model-base-right"},[_c('div',{staticClass:"right-icon-wrap"},[_c('div',{class:{
            'icon-item': true,
            'icon-item-all': true,
            'icon-item-active': _vm.infoSet.currentFloor == -1,
          },on:{"click":function($event){return _vm.onFloor(-1)}}},[_vm._v(" ALL ")]),_c('div',{class:{
            'icon-item': true,
            'icon-item-1f': true,
            'icon-item-active': _vm.infoSet.currentFloor == 0,
          },on:{"click":function($event){return _vm.onFloor(0)}}},[_vm._v(" 1F ")]),_c('div',{class:{
            'icon-item': true,
            'icon-item-2f': true,
            'icon-item-active': _vm.infoSet.currentFloor == 1,
          },on:{"click":function($event){return _vm.onFloor(1)}}},[_vm._v(" 2F ")]),_c('div',{class:{
            'icon-item': true,
            'icon-item-3f': true,
            'icon-item-active': _vm.infoSet.currentFloor == 2,
          },on:{"click":function($event){return _vm.onFloor(2)}}},[_vm._v(" 3F ")])])]):_vm._e(),_c('div',{ref:"viewer",staticClass:"model-content"}),_c('div',{ref:"info",staticClass:"model-info-set"},[(_vm.infoSet.components == 'common')?_c('info-common',{attrs:{"name":_vm.infoSet.current_sub},on:{"point":_vm.onAddPoints}}):_vm._e(),(_vm.sceneSet.current == 'home')?_c('info-home',{on:{"summary":_vm.onSummary}}):_vm._e()],1),_c('div',{staticClass:"model-pointer-wrap"},[_c('pointer-main',{ref:"pointer_main",on:{"ready":_vm.onPointerReady}})],1),(_vm.sceneSet.pointerData)?_c('div',{staticClass:"pointer-data"},[_c('div',[_vm._v(" 位置: "+_vm._s(_vm.sceneSet.pointerData.position)+" ")]),_c('div',[_vm._v(" 相机: "+_vm._s(JSON.stringify(_vm.sceneSet.pointerData.camera))+" ")]),_c('div',[_vm._v(" 模型: "+_vm._s(_vm.sceneSet.pointerData.object_name)+" ")]),_c('div',[_vm._v(" ID: "),_c('Input',{staticStyle:{"width":"100px"},model:{value:(_vm.sceneSet.id),callback:function ($$v) {_vm.$set(_vm.sceneSet, "id", $$v)},expression:"sceneSet.id"}}),_c('Button',{attrs:{"type":"primary"},on:{"click":_vm.onPositionUpdate}},[_vm._v("更新")])],1)]):_vm._e(),(false)?_c('div',{staticClass:"scene-action-wrap"},[_c('div',{staticClass:"scene-action-item"},[_c('span',[_vm._v(_vm._s(_vm.infoSet.current_sub))]),_c('Button',{on:{"click":function($event){return _vm.onScene('home')}}},[_vm._v("外壳")]),_c('Button',{on:{"click":function($event){return _vm.onScene('floor', -1)}}},[_vm._v("楼体")])],1)]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }