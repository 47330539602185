import Axios from 'axios';
import user from './user';
import qs from 'qs';
import moment from 'moment';

const axios = new Axios.create({

});


let getTokenPromise;
const USER_NAME = 'explr';
const USER_PASSWORD = 'explrexplr';

let Network = {
  createTokenPromise() {
    if (getTokenPromise) {
      return getTokenPromise;
    }

    getTokenPromise = new Promise((resolve, reject) => {
      let params = {
        username: USER_NAME,
        password: USER_PASSWORD,
      }

      axios.request({
        // baseURL: 'http://116.131.21.134:8088',
        url: '/api/auth/login',
        method: 'post',
        data: params,
        headers: {
          // 'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        }
      }).then((e) => {

        debugger;
        if (e.data && e.data.data.token) {
          user.setToken(e.data.data.token);
          resolve();
        } else {
          reject();
        }

        setTimeout(() => {
          getTokenPromise = null;
        }, 10);

      }, () => {
        reject();

        setTimeout(() => {
          getTokenPromise = null;
        }, 10);
      });
    });

    return getTokenPromise;
  },

  send: (setting, repeat) => {

    let networkPromise = new Promise((resolve, reject) => {
      let tokenFail = () => {

      }

      let tokenSuccess = () => {
        if (!repeat) {
          Network.send(setting, true).then((res) => {
            resolve(res);
          }, (res) => {
            reject(res);
          });

        }
      }
      
      let token = user.getToken();

      if (!token) {
        Network.createTokenPromise().then(tokenSuccess, tokenFail);

        return;
      }

      setting.headers = {
        'authorization': `Bearer ${token}`,
      };

      // 发送 setting 请求
      let request = axios.request(setting).then((e) => {
        // 如果是 403 则换取 token
        if (e.data.statusCode == -2 && !repeat) {
          Network.createTokenPromise().then(tokenSuccess, tokenFail);
        } else {
          if (e.data && e.data.statusCode == -1) {
            reject(e.data);
          } else {
            resolve(e.data);
          }
        }
      }, (e) => {
        if (e && e.response && e.response.status == 401) {
          Network.createTokenPromise().then(tokenSuccess, tokenFail);
          return;
        }

        reject();
      });
    });


    return networkPromise;
  }
}

export default Network;