<template>
  <div class="info-details-huiyi">
    <div class="info-block-main">
      <div class="huiyi-status">
        会议室状态

        <div :class="{
          'huiyi-flag': true,
          'huiyi-flag-disabled': !huiyiSet.available
        }">
          {{ huiyiSet.available ? '空闲中' : '不可用' }}
        </div>
      </div>
    </div>


    <div class="info-block-main">
      <div class="huiyi-status">
        历史记录

        <div @click="loadHistory" class="huiyi-history">
          查看&gt;
        </div>
      </div>
    </div>

    <Modal v-model="huiyiSet.show" title="会议记录" width="1200">
      <div class="huiyi-list-wrap">
        <Table stripe border class="m_b" :columns="columns" :data="huiyiSet.list"></Table>
      </div>

      <div slot="footer"></div>
    </Modal>
  </div>
</template>

<script>
  import echarts from 'echarts';
  import { mapGetters } from "vuex";

  export default {
    computed: {
      ...mapGetters(["perWidth"]),
    },

    props: {
      useData: {
        type: Object,
        default: null
      },
    },

    mounted () {

    },

    created () {
      this.loadState();
    },

    data () {
      return {
        columns: [
          {
            title: '会议ID',
            width: 150,
            render:(h,params) => {
              return h("span",{style:{fontSize:'12px'}} , params.row.id)
            }
          },

          {
            title: '会议室名称',
            width: 150,
            render:(h,params) => {
              return h("span",{style:{fontSize:'12px'}} , params.row.roomName)
            }
          },

          {
            title: '预约人',
            width: 150,
            render: (h, params) => {
              return h("span",{style:{fontSize:'12px'}} , params.row.speaker);
            }
          },


          {
            title: '会议主题',
            width: 150,
            render: (h, params) => {
              return h("span",{style:{fontSize:'12px'}} , params.row.subject);
            }
          },

          {
            title: '预约时间',
            width: 150,
            render: (h, params) => {
              return h("span",{style:{fontSize:'12px'}} , params.row.createTime);
            }
          },

          {
            title: '开始时间',
            width: 100,
            render: (h, params) => {
              return h("span",{style:{fontSize:'12px'}} , params.row.meetingBeginTime);
            }
          },

          {
            title: '结束时间',
            width: 100,
            render: (h, params) => {
              return h("span",{style:{fontSize:'12px'}} , params.row.meetingEndTime);
            }
          },

          {
            title: '参会人数',
            width: 100,
            render: (h, params) => {
              return h("span",{style:{fontSize:'12px'}} , params.row.attendance);
            }
          },

          {
            title: '状态',
            minWidth: 100,
            render: (h) => {
              return h("span",{style:{fontSize:'12px'}} , '暂无');
            }
          },
        ],
        huiyiSet: {
          available: false,
          show: false,
          list: [],
        }
      }
    },

    methods: {
      loadHistory () {
        $.ajax({
          url: '/api/device/meetingRoom/records',
          loading: true,
        }).then((ret) => {
          if (ret.code != 'success') {
            return;
          }
          debugger
          this.huiyiSet.list = ret.data;
          this.huiyiSet.show = true;
        });
      },

      loadState () {
        $.ajax({
          url: '/api/device/meetingRoom/status',
        }).then((ret) => {
          if (ret.code != 'success') {
            return;
          }

          this.huiyiSet.available = ret.data.available;
        });
      }
    }
  };
</script>

<style lang="less">
  @import "~@/assets/css/mixin.less";

  .info-details-huiyi {
    .huiyi-status {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      padding: 0 10px;
      color: #fff;
      padding: 0 20px;

      .huiyi-history {
        width: 75px;
        height: 34px;
        border: 1px solid #2D3862;
        background: linear-gradient(180deg, #7691F5 0%, #7BD9EC 100%);
        opacity: 1;
        border-radius: 10px;
        line-height: 34px;
        text-align: center;
        cursor: pointer;
      }

      .huiyi-flag {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        &.huiyi-flag-disabled {
          &:before {
            background: red;
          }
        }

        &:before {
          content: "";
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 4px;
          background: #12B796;
          margin-right: 10px;
        }
      }
    }
  }

</style>
