import Vue from 'vue'
import iView from 'iview'
import Router from 'vue-router'

// 基础模块
import layout from '@/view/pages/layout'
import store from '@/store'
import user from '@/assets/user'

import model_index from '@/view/pages/model/index'
import account_login from '@/view/pages/account/login'

Vue.use(Router)

let router = new Router({
  routes: [
    {
      path: '/',     
      component: layout,
      children:[
        {
          path: '',
          component: model_index,
          name: 'model_index',
          meta: {
            auth: true
          }
        }
      ]
    },

    {
      path: '/account/login',
      component: account_login,
      name: 'account_login',
      meta: {
        auth: false
      }
    },
  ]
});

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title;
  }

  store.dispatch('set_routeName', to.name);

  user.getInfo().then((ret) => {
    if (to.meta.auth === false) {
      next({
        name: 'home'
      });
    } else {
      store.dispatch('set_user', ret);
      next();
    }
  }, () => {
    user.clear();

    if (to.meta.auth === true) {
      next({
        name: 'account_login'
      });
    } else {
      next();
    }
  });
});

router.afterEach((to, from, next) => {
  iView.LoadingBar.finish();
  window.scrollTo(0, 0);
});

export default router